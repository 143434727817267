import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { LoaderDialogProvider } from './contexts/LoaderDialogContext'

import Routes from './Routes/Routes'
import { ProfileViewerProvider } from './contexts/ProfileViewerContext'

function App() {
  return (
    <div>
      <LoaderDialogProvider>
        <ProfileViewerProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ProfileViewerProvider>
      </LoaderDialogProvider>
    </div>
  )
}

export default App
