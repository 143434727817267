import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = ({ stops }) => {
    const mapContainerRef = useRef(null);

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [stops[0].gpsCoordinates[1], stops[0].gpsCoordinates[0]], // [lng, lat]
            zoom: 12,
        });

        // Add navigation control (the +/- zoom buttons)
        map.addControl(new mapboxgl.NavigationControl(), 'top-right');

        const bounds = new mapboxgl.LngLatBounds();

        // Add markers to the map
        stops.forEach((stop,i)=> {
            const [lat, lng] = stop.gpsCoordinates;

            const el = document.createElement('div');
            el.className = 'marker';
            el.innerHTML = `
                <div class='marker-label'><strong>${stop.name}</strong></div>
                <div class='marker-icon'> ${i+1}</div>
            `;
            new mapboxgl.Marker({
                    element: el,
                    anchor: 'bottom',
                })
                .setLngLat([lng, lat]) // [lng, lat]
                .addTo(map)
                .setOffset([0, 0]);

            bounds.extend([lng, lat]);
        });

        

        map.fitBounds(bounds, {
            padding: 50 // Adjust padding as needed
        });

        // Clean up on unmount
        return () => map.remove();
    }, [stops]);

    return <div ref={mapContainerRef} className="map-container" />;
};

export default Map;
