import React, { useState, useContext, useEffect } from 'react';
import { LOGIN } from '../constants';
import axios from 'axios'

import { auth } from '../firebase';
import {
  signInWithCustomToken,
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';

const ProfileViewerContext = React.createContext();

export function useProfileViewer() {
  return useContext(ProfileViewerContext);
}

export function ProfileViewerProvider({ children }) {
  const [idToken, setIdToken] = useState(null);

  const value = {
    idToken
  };

  useEffect(()=>{
    const login = async () => {
      try {
        const response = await axios.post(
          LOGIN,
          {
            email: 'tempProfileViewer@insidartours.com',
            password: 'testPassword',
            platform: 'mobile',
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.status === 200) {
          setPersistence(auth, browserLocalPersistence)
          .then(async () => {
            await signInWithCustomToken(auth, response.data?.token);
          })
          .catch((error) => {
            console.error(error);
          });
        }
      } catch (error) {
          setIdToken(null)
          console.error('Login failed', error);
      }
    };

    login();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then((token) => {
          setIdToken(token);
        });
      }
    });

    return unsubscribe;
  },[])

  return (
    <ProfileViewerContext.Provider value={value}>
      {children}
    </ProfileViewerContext.Provider>
  );
}
