import phones from '../../assets/Home/Phones.png'
import tick from '../../assets/icons/check.svg'
import './styles/guidePage.css'

import bgMapImage from '../../assets/imgs/bgMap.png';

function GuidePage({ content }) {
  return (
    <div id="GuidePage" 
      style={{
          position: 'relative',
          overflow: 'hidden',
        }}>
      <div
          className="background-overlay"
          style={{
            position: 'absolute',
            top: '0', // Adjust to spill 20% above
            left: '0',
            right: '0',
            bottom: '0',
            backgroundImage: `linear-gradient(to bottom, rgba(232, 232, 232, 1) 0%, rgba(232, 232, 232, 0) 100%), url(${bgMapImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '160% 100%',
            backgroundPosition: 'bottom',
            opacity: '0.7',
            zIndex: '-1',
          }}
      ></div>
      <div className="GuideH">
        <h1>
          <q>{content['text']['advantages_title']['text']}</q>
        </h1>
      </div>
      <p className="hide_for_mobile">{content['text']['advantages_para']['text']}</p>
      <div className="GuideInfo">
        <div>
          <div className="infoTag">
            <p>{content['text']['advantage_1']['text']}</p>
            <img src={tick} alt="Greentick" />
          </div>
          <div className="infoTag">
            <p>{content['text']['advantage_2']['text']}</p>
            <img src={tick} alt="greentick" />
          </div>
          <div className="infoTag">
            <p>{content['text']['advantage_3']['text']}</p>
            <img src={tick} alt="Greentick" />
          </div>
        </div>
        <div className="Gphone">
          <img
            src={
              'Phones' in content['pictures']
                ? content['pictures']['Phones']['pictureURL']
                : phones
            }
            alt="phones"
          />
        </div>
        <div>
          <div className="infoTagR">
            <img src={tick} alt="Greentick" />
            <p>{content['text']['advantage_4']['text']}</p>
          </div>
          <div className="infoTagR">
            <img src={tick} alt="Greentick" />
            <p>{content['text']['advantage_5']['text']}</p>
          </div>
          <div className="infoTagR">
            <img src={tick} alt="Greentick" />
            <p>{content['text']['advantage_6']['text']}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GuidePage
