import React from 'react';
import './style/starRating.css';

const StarRating = ({ rating, isAlt }) => {
    const fullStars = Math.floor(rating);
    const halfStars = rating % 1 !== 0 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    return (
        <div className={isAlt ? "star-rating-alt" : "star-rating"}>
            {[...Array(fullStars)].map((_, i) => (
                <i key={i} className="fas fa-star"></i>
            ))}
            {halfStars === 1 && <i className="fas fa-star-half-alt"></i>}
            {[...Array(emptyStars)].map((_, i) => (
                <i key={i + fullStars + halfStars} className="far fa-star"></i>
            ))}
        </div>
    );
};

export default StarRating;
