import React, { useState, useContext, useCallback } from 'react'
import LoaderDialog from '../Components/LoaderDialog/LoaderDialog'

const LoaderDialogContext = React.createContext()

export function useLoaderDialog() {
  return useContext(LoaderDialogContext)
}

export function LoaderDialogProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true)

  const showLoader = () => {
    setIsLoading(true)
  }

  const hideLoader = () => {
    setIsLoading(false)
  }

  const value = {
    isLoading,
    showLoader,
    hideLoader,
  }

  return (
    <LoaderDialogContext.Provider value={value}>
      {children}
      {isLoading && <LoaderDialog />}
    </LoaderDialogContext.Provider>
  )
}
