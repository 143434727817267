export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoidGVzdGFjY291bnQxNDA0IiwiYSI6ImNrd3VrYm8wNjBqcjcybnFuaGZ2MnA5OXUifQ.EZxAMOylo0Y6TO-i8qMMJw";
export const FIREBASE_API_KEY = "AIzaSyAu-DZk1vdEUiPKvbOVMxtUNTc_TBNsjQM";

const API_HOST = "https://api.insidartours.com/";
export const READ_AVAILABLE_LANGUAGE =
  API_HOST + "v1/website/readAvailableLanguages/";
export const READ_LANGUAGE = API_HOST + "v1/website/readLanguage/";

export const REGISTER = API_HOST + "auth/register/";
export const FORGOT_PASSWORD = API_HOST + "auth/forgot/";
export const CHANGE_PASSWORD = API_HOST + "auth/change/";
export const READ_AVAILABLE_LANGUAGES =
  API_HOST + "v1/web/readAvailableLanguages/";

export const LOGIN = API_HOST + "v1/auth/login/";

export const READ_USER_DATA = API_HOST + "v1/userData/read/";
export const CREATE_USER_DATA = API_HOST + "userData/create/";
export const UPDATE_USER_DATA = API_HOST + "userData/update/";

export const READ_USER_PREF = API_HOST + "userData/readPref/";
export const CREATE_USER_PREF = API_HOST + "userData/createPref/";
export const UPDATE_USER_PREF = API_HOST + "userData/updatePref/";

export const READ_TOUR_DATA = API_HOST + "v1/tourData/read/";
export const CREATE_TOUR_DATA = API_HOST + "tourData/create/";
export const UPDATE_TOUR_DATA = API_HOST + "tourData/update/";

export const READ_AR_DATA = API_HOST + "arData/read/";
export const CREATE_AR_DATA = API_HOST + "arData/create/";
export const UPDATE_AR_DATA = API_HOST + "arData/update/";

export const READ_WEBSITE_DATA = API_HOST + "v1/website/read/";

export const READ_ANALYTICS = API_HOST + "analytics/read/";

export const SEARCH_BY_GUIDE = API_HOST + "search/guide/";

export const READ_TOUR_REVIEWS = API_HOST + "v1/reviews/read/";
export const READ_GUIDE_DATA = API_HOST + "v1/guideData/read/";

export const DIALOG_CONSTANTS = () => {
  let dialogs = {};

  dialogs.INVALID_EMAIL_PASSWORD_DIALOG = {
    type: "error",
    message: "Invalid email address or password",
    btnTxt: "Try Again",
  };

  dialogs.NEW_USER_CREATION_FAILED_DIALOG = {
    type: "error",
    message: "Failed to create user",
    btnTxt: "Try Again",
  };

  dialogs.RESET_EMAIL_SENT_DIALOG = {
    type: "success",
    message:
      "We have sent instructions to change your password to your email address",
    btnTxt: "OK",
  };

  dialogs.NO_EMAIL_ADDRESS_DIALOG = {
    type: "error",
    message: "There is no account linked to this email address",
    btnTxt: "Try Again",
  };

  dialogs.PASSWORD_CHANGE_SUCCESS_DIALOG = {
    type: "success",
    message:
      "We have sent instructions to change your password to your email address",
    btnTxt: "OK",
  };

  return dialogs;
};

export const COUNTRY_TO_CITY_MAP = {
  "Hong Kong": ["Hong Kong"],
  Thailand: ["Bangkok", "Phuket", "Pattaya", "Chiang Mai", "Krabi"],
  "United Kingdom": ["London", "Edinburgh"],
  Pakistan: ["Lahore"],
  Singapore: ["Singapore"],
  France: ["Paris", "Nice"],
  "United Arab Emirates": ["Dubai", "Abu Dhabi"],
  "United States": [
    "New York City",
    "Miami",
    "Las Vegas",
    "Los Angeles",
    "Orlando",
    "San Francisco",
    "Honolulu",
    "Washington D.C.",
    "Chicago",
    "Boston",
    "San Jose",
    "Houston",
    "Atlanta",
    "Dallas",
  ],
  Malaysia: ["Kuala Lumpur", "Johor Bahru", "Penang Island"],
  Turkey: ["Istanbul", "Antalya", "Muğla"],
  India: [
    "Delhi",
    "Mumbai",
    "Agra",
    "Chennai",
    "Jaipur",
    "Kolkata",
    "Bangalore",
    "Pune",
  ],
  China: [
    "Shenzhen",
    "Guangzhou",
    "Shanghai",
    "Beijing",
    "Zhuhai",
    "Guilin",
    "Chengdu",
    "Xi'an",
    "Xiamen",
    "Dalian",
    "Hangzhou",
    "Tianjin",
    "Qingdao",
  ],
  Italy: ["Rome", "Milan", "Venice", "Florence"],
  Japan: ["Tokyo", "Osaka", "Kyoto", "Chiba", "Fukuoka"],
  Taiwan: ["Taipei"],
  "Saudi Arabia": ["Mecca", "Medina", "Riyadh", "Dammam"],
  "Czech Republic": ["Prague"],
  "South Korea": ["Seoul", "Jeju"],
  Netherlands: ["Amsterdam"],
  Vietnam: ["Ho Chi Minh City", "Ha Long", "Hanoi", "Da Nang"],
  Indonesia: ["Denpasar", "Jakarta", "Batam"],
  Spain: ["Barcelona", "Madrid"],
  Austria: ["Vienna"],
  Mexico: ["Cancún", "Mexico City"],
  Germany: ["Berlin", "Munich", "Frankfurt am Main", "Düsseldorf", "Hamburg"],
  Egypt: ["Cairo", "Hurghada"],
  Russia: ["Moscow", "Saint Petersburg"],
  Ireland: ["Dublin"],
  Israel: ["Jerusalem", "Tel Aviv"],
  Canada: ["Toronto", "Vancouver", "Montreal"],
  "South Africa": ["Johannesburg", "Cape Town", "Durban"],
  Australia: ["Sydney", "Melbourne"],
  Belgium: ["Brussels"],
  Hungary: ["Budapest"],
  Portugal: ["Lisbon", "Porto"],
  Greece: ["Heraklion", "Rhodes"],
  Denmark: ["Copenhagen"],
  Poland: ["Warsaw", "Kraków"],
  Morocco: ["Marrakesh", "Casablanca"],
  Philippines: ["Cebu City", "Manila"],
  "New Zealand": ["Auckland"],
  Argentina: ["Buenos Aires"],
  Sweden: ["Stockholm"],
  Peru: ["Lima"],
  Brazil: ["Rio de Janeiro", "São Paulo"],
  "Dominican Republic": ["Punta Cana"],
  " Switzerland": ["Zürich", "Geneva"],
  Iran: ["Tehran"],
  Colombia: ["Bogota"],
  Lebanon: ["Beirut"],
  "Sri Lanka": ["Colombo"],
  Romania: ["Bucharest"],
  Bulgaria: ["Sofia"],
  Uruguay: ["Montevideo"],
  Jordan: ["Amman"],
  Ghana: ["Accra"],
  Ecuador: ["Quito"],
  Nigeria: ["Lagos"],
};

export const CITY_OPTIONS = [
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Bangkok", label: "Bangkok" },
  { value: "London", label: "London" },
  { value: "Lahore", label: "Lahore" },
  { value: "Singapore", label: "Singapore" },
  { value: "Paris", label: "Paris" },
  { value: "New York City", label: "New York City" },
  { value: "Dubai", label: "Dubai" },
  { value: "Istanbul", label: "Istanbul" },
  { value: "Kuala Lumpur", label: "Kuala Lumpur" },
  { value: "Antalya", label: "Antalya" },
  { value: "Delhi", label: "Delhi" },
  { value: "Mumbai", label: "Mumbai" },
  { value: "Shenzhen", label: "Shenzhen" },
  { value: "Rome", label: "Rome" },
  { value: "Phuket", label: "Phuket" },
  { value: "Pattaya", label: "Pattaya" },
  { value: "Tokyo", label: "Tokyo" },
  { value: "Mecca", label: "Mecca" },
  { value: "Taipei", label: "Taipei" },
  { value: "Prague", label: "Prague" },
  { value: "Guangzhou", label: "Guangzhou" },
  { value: "Seoul", label: "Seoul" },
  { value: "Medina", label: "Medina" },
  { value: "Agra", label: "Agra" },
  { value: "Amsterdam", label: "Amsterdam" },
  { value: "Osaka", label: "Osaka" },
  { value: "Miami", label: "Miami" },
  { value: "Shanghai", label: "Shanghai" },
  { value: "Las Vegas", label: "Las Vegas" },
  { value: "Denpasar", label: "Denpasar" },
  { value: "Ho Chi Minh City", label: "Ho Chi Minh City" },
  { value: "Los Angeles", label: "Los Angeles" },
  { value: "Barcelona", label: "Barcelona" },
  { value: "Chennai", label: "Chennai" },
  { value: "Milan", label: "Milan" },
  { value: "Johor Bahru", label: "Johor Bahru" },
  { value: "Vienna", label: "Vienna" },
  { value: "Cancún", label: "Cancún" },
  { value: "Jaipur", label: "Jaipur" },
  { value: "Cairo", label: "Cairo" },
  { value: "Berlin", label: "Berlin" },
  { value: "Moscow", label: "Moscow" },
  { value: "Orlando", label: "Orlando" },
  { value: "Madrid", label: "Madrid" },
  { value: "Venice", label: "Venice" },
  { value: "Riyadh", label: "Riyadh" },
  { value: "Ha Long", label: "Ha Long" },
  { value: "Florence", label: "Florence" },
  { value: "Dublin", label: "Dublin" },
  { value: "Hanoi", label: "Hanoi" },
  { value: "Jerusalem", label: "Jerusalem" },
  { value: "Johannesburg", label: "Johannesburg" },
  { value: "Toronto", label: "Toronto" },
  { value: "Munich", label: "Munich" },
  { value: "Sydney", label: "Sydney" },
  { value: "Beijing", label: "Beijing" },
  { value: "Jakarta", label: "Jakarta" },
  { value: "Brussels", label: "Brussels" },
  { value: "Saint Petersburg", label: "Saint Petersburg" },
  { value: "Lisbon", label: "Lisbon" },
  { value: "Budapest", label: "Budapest" },
  { value: "Penang Island", label: "Penang Island" },
  { value: "Dammam", label: "Dammam" },
  { value: "Kyoto", label: "Kyoto" },
  { value: "Heraklion", label: "Heraklion" },
  { value: "Vancouver", label: "Vancouver" },
  { value: "Zhuhai", label: "Zhuhai" },
  { value: "Copenhagen", label: "Copenhagen" },
  { value: "Chiang Mai", label: "Chiang Mai" },
  { value: "Melbourne", label: "Melbourne" },
  { value: "San Francisco", label: "San Francisco" },
  { value: "Marrakesh", label: "Marrakesh" },
  { value: "Warsaw", label: "Warsaw" },
  { value: "Cebu City", label: "Cebu City" },
  { value: "Kolkata", label: "Kolkata" },
  { value: "Tel Aviv", label: "Tel Aviv" },
  { value: "Auckland", label: "Auckland" },
  { value: "Honolulu", label: "Honolulu" },
  { value: "Guilin", label: "Guilin" },
  { value: "Kraków", label: "Kraków" },
  { value: "Hurghada", label: "Hurghada" },
  { value: "Buenos Aires", label: "Buenos Aires" },
  { value: "Muğla", label: "Muğla" },
  { value: "Frankfurt am Main", label: "Frankfurt am Main" },
  { value: "Chiba", label: "Chiba" },
  { value: "Lima", label: "Lima" },
  { value: "Stockholm", label: "Stockholm" },
  { value: "Batam", label: "Batam" },
  { value: "Da Nang", label: "Da Nang" },
  { value: "Fukuoka", label: "Fukuoka" },
  { value: "Nice", label: "Nice" },
  { value: "Jeju", label: "Jeju" },
  { value: "Abu Dhabi", label: "Abu Dhabi" },
  { value: "Rhodes", label: "Rhodes" },
  { value: "Porto", label: "Porto" },
  { value: "Krabi", label: "Krabi" },
  { value: "Rio de Janeiro", label: "Rio de Janeiro" },
  { value: "Mexico City", label: "Mexico City" },
  { value: "Bangalore", label: "Bangalore" },
  { value: "São Paulo", label: "São Paulo" },
  { value: "Punta Cana", label: "Punta Cana" },
  { value: "Montreal", label: "Montreal" },
  { value: "Zürich", label: "Zürich" },
  { value: "Chicago", label: "Chicago" },
  { value: "Washington D.C.", label: "Washington D.C." },
  { value: "Boston", label: "Boston" },
  { value: "Düsseldorf", label: "Düsseldorf" },
  { value: "Edinburgh", label: "Edinburgh" },
  { value: "Chengdu", label: "Chengdu" },
  { value: "Tehran", label: "Tehran" },
  { value: "San Jose", label: "San Jose" },
  { value: "Hamburg", label: "Hamburg" },
  { value: "Houston", label: "Houston" },
  { value: "Manila", label: "Manila" },
  { value: "Cape Town", label: "Cape Town" },
  { value: "Xi'an", label: "Xi'an" },
  { value: "Bogota", label: "Bogota" },
  { value: "Geneva", label: "Geneva" },
  { value: "Beirut", label: "Beirut" },
  { value: "Xiamen", label: "Xiamen" },
  { value: "Colombo", label: "Colombo" },
  { value: "Casablanca", label: "Casablanca" },
  { value: "Bucharest", label: "Bucharest" },
  { value: "Sofia", label: "Sofia" },
  { value: "Atlanta", label: "Atlanta" },
  { value: "Montevideo", label: "Montevideo" },
  { value: "Dalian", label: "Dalian" },
  { value: "Hangzhou", label: "Hangzhou" },
  { value: "Amman", label: "Amman" },
  { value: "Durban", label: "Durban" },
  { value: "Pune", label: "Pune" },
  { value: "Accra", label: "Accra" },
  { value: "Dallas", label: "Dallas" },
  { value: "Tianjin", label: "Tianjin" },
  { value: "Quito", label: "Quito" },
  { value: "Lagos", label: "Lagos" },
  { value: "Qingdao", label: "Qingdao" },
];

export const COUNTRY_OPTIONS = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Brazil", label: "Brazil" },
  { value: "British Virgin Islands", label: "British Virgin Islands" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Caribbean Netherlands", label: "Caribbean Netherlands" },
  { value: "Cayman Islands", label: "Cayman Islands" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Channel Islands", label: "Channel Islands" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  { value: "Cook Islands", label: "Cook Islands" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Curaçao", label: "Curaçao" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czechia", label: "Czechia" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "DR Congo", label: "DR Congo" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Faeroe Islands", label: "Faeroe Islands" },
  { value: "Falkland Islands", label: "Falkland Islands" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "French Guiana", label: "French Guiana" },
  { value: "French Polynesia", label: "French Polynesia" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Greece", label: "Greece" },
  { value: "Greenland", label: "Greenland" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Guam", label: "Guam" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Holy See", label: "Holy See" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Isle of Man", label: "Isle of Man" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Laos", label: "Laos" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Macao", label: "Macao" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Martinique", label: "Martinique" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mayotte", label: "Mayotte" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Caledonia", label: "New Caledonia" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Niue", label: "Niue" },
  { value: "North Korea", label: "North Korea" },
  { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Qatar", label: "Qatar" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Réunion", label: "Réunion" },
  { value: "Saint-Barthélemy", label: "Saint-Barthélemy" },
  { value: "Saint Helena", label: "Saint Helena" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Saint Martin (French part)", label: "Saint Martin (French part)" },
  { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Sint Maarten (Dutch part)", label: "Sint Maarten (Dutch part)" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Korea", label: "South Korea" },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "St. Vincent & Grenadines", label: "St. Vincent & Grenadines" },
  { value: "State of Palestine", label: "State of Palestine" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Swaziland", label: "Swaziland" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syria", label: "Syria" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "North Macedonia", label: "North Macedonia" },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tokelau", label: "Tokelau" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "U.K.", label: "U.K." },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  {
    value: "United States of America (USA)",
    label: "United States of America (USA)",
  },
  {
    value: "United States Virgin Islands",
    label: "United States Virgin Islands",
  },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Wallis and Futuna", label: "Wallis and Futuna" },
  { value: "Western Sahara", label: "Western Sahara" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
];

export const LANGUAGE_OPTIONS = [
  { value: " CHINESE, MANDARIN [CHN]", label: "CHINESE, MANDARIN [CHN]" },
  { value: "SPANISH [SPN]", label: "SPANISH [SPN]" },
  { value: "ENGLISH [ENG]", label: "ENGLISH [ENG]" },
  { value: "BENGALI [BNG]", label: "BENGALI [BNG]" },
  { value: "HINDI [HND]", label: "HINDI [HND]" },
  { value: "PORTUGUESE [POR]", label: "PORTUGUESE [POR]" },
  { value: "RUSSIAN [RUS]", label: "RUSSIAN [RUS]" },
  { value: "JAPANESE [JPN]", label: "JAPANESE [JPN]" },
  { value: "GERMAN, STANDARD [GER]", label: "GERMAN, STANDARD [GER]" },
  { value: "CHINESE, WU [WUU]", label: "CHINESE, WU [WUU]" },
  { value: "JAVANESE [JAN]", label: "JAVANESE [JAN]" },
  { value: "KOREAN [KKN]", label: "KOREAN [KKN]" },
  { value: "FRENCH [FRN]", label: "FRENCH [FRN]" },
  { value: "VIETNAMESE [VIE]", label: "VIETNAMESE [VIE]" },
  { value: "TELUGU [TCW]", label: "TELUGU [TCW]" },
  { value: "CHINESE, YUE [YUH]", label: "CHINESE, YUE [YUH]" },
  { value: "MARATHI [MRT]", label: "MARATHI [MRT]" },
  { value: "TAMIL [TCV]", label: "TAMIL [TCV]" },
  { value: "TURKISH [TRK]", label: "TURKISH [TRK]" },
  { value: "URDU [URD]", label: "URDU [URD]" },
  { value: "CHINESE, MIN NAN [CFR]", label: "CHINESE, MIN NAN [CFR]" },
  { value: "CHINESE, JINYU [CJY]", label: "CHINESE, JINYU [CJY]" },
  { value: "GUJARATI [GJR]", label: "GUJARATI [GJR]" },
  { value: "POLISH [PQL]", label: "POLISH [PQL]" },
  {
    value: "ARABIC, EGYPTIAN SPOKEN [ARZ]",
    label: "ARABIC, EGYPTIAN SPOKEN [ARZ]",
  },
  { value: "UKRAINIAN [UKR]", label: "UKRAINIAN [UKR]" },
  { value: "ITALIAN [ITN]", label: "ITALIAN [ITN]" },
  { value: "CHINESE, XIANG [HSN]", label: "CHINESE, XIANG [HSN]" },
  { value: "MALAYALAM [MJS]", label: "MALAYALAM [MJS]" },
  { value: "CHINESE, HAKKA [HAK]", label: "CHINESE, HAKKA [HAK]" },
  { value: "KANNADA [KJV]", label: "KANNADA [KJV]" },
  { value: "ORIYA [ORY]", label: "ORIYA [ORY]" },
  { value: "PANJABI, WESTERN [PNB]", label: "PANJABI, WESTERN [PNB]" },
  { value: "SUNDA [SUO]", label: "SUNDA [SUO]" },
  { value: "PANJABI, EASTERN [PNJ]", label: "PANJABI, EASTERN [PNJ]" },
  { value: "ROMANIAN [RUM]", label: "ROMANIAN [RUM]" },
  { value: "BHOJPURI [BHJ]", label: "BHOJPURI [BHJ]" },
  { value: "AZERBAIJANI, SOUTH [AZB]", label: "AZERBAIJANI, SOUTH [AZB]" },
  { value: "FARSI, WESTERN [PES]", label: "FARSI, WESTERN [PES]" },
  { value: "MAITHILI [MKP]", label: "MAITHILI [MKP]" },
  { value: "HAUSA [HUA]", label: "HAUSA [HUA]" },
  {
    value: "ARABIC, ALGERIAN SPOKEN [ARQ]",
    label: "ARABIC, ALGERIAN SPOKEN [ARQ]",
  },
  { value: "BURMESE [BMS]", label: "BURMESE [BMS]" },
  { value: "SERBO-CROATIAN [SRC]", label: "SERBO-CROATIAN [SRC]" },
  { value: "CHINESE, GAN [KNN]", label: "CHINESE, GAN [KNN]" },
  { value: "AWADHI [AWD]", label: "AWADHI [AWD]" },
  { value: "THAI [THJ]", label: "THAI [THJ]" },
  { value: "DUTCH [DUT]", label: "DUTCH [DUT]" },
  { value: "YORUBA [YOR]", label: "YORUBA [YOR]" },
  { value: "SINDHI [SND]", label: "SINDHI [SND]" },
  {
    value: "ARABIC, MOROCCAN SPOKEN [ARY]",
    label: "ARABIC, MOROCCAN SPOKEN [ARY]",
  },
  { value: "ARABIC, SAIDI SPOKEN [AEC]", label: "ARABIC, SAIDI SPOKEN [AEC]" },
  { value: "UZBEK, NORTHERN [UZB]", label: "UZBEK, NORTHERN [UZB]" },
  { value: "MALAY [MLI]", label: "MALAY [MLI]" },
  { value: "AMHARIC [AMH]", label: "AMHARIC [AMH]" },
  { value: "INDONESIAN [INZ]", label: "INDONESIAN [INZ]" },
  { value: "IGBO [IGR]", label: "IGBO [IGR]" },
  { value: "TAGALOG [TGL]", label: "TAGALOG [TGL]" },
  { value: "NEPALI [NEP]", label: "NEPALI [NEP]" },
  {
    value: "ARABIC, SUDANESE SPOKEN [APD]",
    label: "ARABIC, SUDANESE SPOKEN [APD]",
  },
  { value: "SARAIKI [SKR]", label: "SARAIKI [SKR]" },
  { value: "CEBUANO [CEB]", label: "CEBUANO [CEB]" },
  {
    value: "ARABIC, NORTH LEVANTINE SPOKEN [APC]",
    label: "ARABIC, NORTH LEVANTINE SPOKEN [APC]",
  },
  { value: "THAI, NORTHEASTERN [TTS]", label: "THAI, NORTHEASTERN [TTS]" },
  { value: "ASSAMESE [ASM]", label: "ASSAMESE [ASM]" },
  { value: "HUNGARIAN [HNG]", label: "HUNGARIAN [HNG]" },
  { value: "CHITTAGONIAN [CIT]", label: "CHITTAGONIAN [CIT]" },
  {
    value: "ARABIC, MESOPOTAMIAN SPOKEN [ACM]",
    label: "ARABIC, MESOPOTAMIAN SPOKEN [ACM]",
  },
  { value: "MADURA [MHJ]", label: "MADURA [MHJ]" },
  { value: "SINHALA [SNH]", label: "SINHALA [SNH]" },
  { value: "HARYANVI [BGC]", label: "HARYANVI [BGC]" },
  { value: "MARWARI [MKD]", label: "MARWARI [MKD]" },
  { value: "CZECH [CZC]", label: "CZECH [CZC]" },
  { value: "GREEK [GRK]", label: "GREEK [GRK]" },
  { value: "MAGAHI [MQM]", label: "MAGAHI [MQM]" },
  { value: "CHHATTISGARHI [HNE]", label: "CHHATTISGARHI [HNE]" },
  { value: "DECCAN [DCC]", label: "DECCAN [DCC]" },
  { value: "CHINESE, MIN BEI [MNP]", label: "CHINESE, MIN BEI [MNP]" },
  { value: "BELARUSAN [RUW]", label: "BELARUSAN [RUW]" },
  { value: "ZHUANG, NORTHERN [CCX]", label: "ZHUANG, NORTHERN [CCX]" },
  { value: "ARABIC, NAJDI SPOKEN [ARS]", label: "ARABIC, NAJDI SPOKEN [ARS]" },
  { value: "PASHTO, NORTHERN [PBU]", label: "PASHTO, NORTHERN [PBU]" },
  { value: "SOMALI [SOM]", label: "SOMALI [SOM]" },
  { value: "MALAGASY [MEX]", label: "MALAGASY [MEX]" },
  {
    value: "ARABIC, TUNISIAN SPOKEN [AEB]",
    label: "ARABIC, TUNISIAN SPOKEN [AEB]",
  },
  { value: "RWANDA [RUA]", label: "RWANDA [RUA]" },
  { value: "ZULU [ZUU]", label: "ZULU [ZUU]" },
  { value: "BULGARIAN [BLG]", label: "BULGARIAN [BLG]" },
  { value: "SWEDISH [SWD]", label: "SWEDISH [SWD]" },
  { value: "LOMBARD [LMO]", label: "LOMBARD [LMO]" },
  { value: "OROMO, WEST-CENTRAL [GAZ]", label: "OROMO, WEST-CENTRAL [GAZ]" },
  { value: "PASHTO, SOUTHERN [PBT]", label: "PASHTO, SOUTHERN [PBT]" },
  { value: "KAZAKH [KAZ]", label: "KAZAKH [KAZ]" },
  { value: "ILOCANO [ILO]", label: "ILOCANO [ILO]" },
  { value: "TATAR [TTR]", label: "TATAR [TTR]" },
  { value: "FULFULDE, NIGERIAN [FUV]", label: "FULFULDE, NIGERIAN [FUV]" },
  {
    value: "ARABIC, SANAANI SPOKEN [AYN]",
    label: "ARABIC, SANAANI SPOKEN [AYN]",
  },
  { value: "UYGHUR [UIG]", label: "UYGHUR [UIG]" },
  {
    value: "HAITIAN CREOLE FRENCH [HAT]",
    label: "HAITIAN CREOLE FRENCH [HAT]",
  },
  { value: "AZERBAIJANI, NORTH [AZE]", label: "AZERBAIJANI, NORTH [AZE]" },
  { value: "NAPOLETANO-CALABRESE [NPL]", label: "NAPOLETANO-CALABRESE [NPL]" },
  { value: "KHMER, CENTRAL [KMR]", label: "KHMER, CENTRAL [KMR]" },
  { value: "FARSI, EASTERN [PRS]", label: "FARSI, EASTERN [PRS]" },
  { value: "AKAN [TWS]", label: "AKAN [TWS]" },
  { value: "HILIGAYNON [HIL]", label: "HILIGAYNON [HIL]" },
  { value: "KURMANJI [KUR]", label: "KURMANJI [KUR]" },
  { value: "SHONA [SHD]", label: "SHONA [SHD]" },
];

export const CURRENCY_OPTIONS = [
  { label: "Afghan afghani", value: "AFN" },
  { label: "European euro", value: "EUR" },
  { label: "Albanian lek", value: "ALL" },
  { label: "Algerian dinar", value: "DZD" },
  { label: "United States dollar", value: "USD" },
  { label: "Angolan kwanza", value: "AOA" },
  { label: "East Caribbean dollar", value: "XCD" },
  { label: "Argentine peso", value: "ARS" },
  { label: "Armenian dram", value: "AMD" },
  { label: "Aruban florin", value: "AWG" },
  { label: "Saint Helena pound", value: "SHP" },
  { label: "Australian dollar", value: "AUD" },
  { label: "Azerbaijan manat", value: "AZN" },
  { label: "Bahamian dollar", value: "BSD" },
  { label: "Bahraini dinar", value: "BHD" },
  { label: "Bangladeshi taka", value: "BDT" },
  { label: "Barbadian dollar", value: "BBD" },
  { label: "Belarusian ruble", value: "BYN" },
  { label: "Belize dollar", value: "BZD" },
  { label: "West African CFA franc", value: "XOF" },
  { label: "Bermudian dollar", value: "BMD" },
  { label: "Bhutanese ngultrum", value: "BTN" },
  { label: "Bolivian boliviano", value: "BOB" },
  { label: "Bosnia and Herzegovina convertible mark", value: "BAM" },
  { label: "Botswana pula", value: "BWP" },
  { label: "Brazilian real", value: "BRL" },
  { label: "Brunei dollar", value: "BND" },
  { label: "Bulgarian lev", value: "BGN" },
  { label: "Burundi franc", value: "BIF" },
  { label: "Cabo Verdean escudo", value: "CVE" },
  { label: "Cambodian riel", value: "KHR" },
  { label: "Central African CFA franc", value: "XAF" },
  { label: "Canadian dollar", value: "CAD" },
  { label: "Cayman Islands dollar", value: "KYD" },
  { label: "New Zealand dollar", value: "NZD" },
  { label: "Chilean peso", value: "CLP" },
  { label: "Chinese Yuan Renminbi", value: "CNY" },
  { label: "Colombian peso", value: "COP" },
  { label: "Comorian franc", value: "KMF" },
  { label: "Congolese franc", value: "CDF" },
  { label: "Costa Rican colon", value: "CRC" },
  { label: "Croatian kuna", value: "HRK" },
  { label: "Cuban peso", value: "CUP" },
  { label: "Netherlands Antillean guilder", value: "ANG" },
  { label: "Czech koruna", value: "CZK" },
  { label: "Danish krone", value: "DKK" },
  { label: "Djiboutian franc", value: "DJF" },
  { label: "Dominican peso", value: "DOP" },
  { label: "Egyptian pound", value: "EGP" },
  { label: "Eritrean nakfa", value: "ERN" },
  { label: "Swazi lilangeni", value: "SZL" },
  { label: "Ethiopian birr", value: "ETB" },
  { label: "Falkland Islands pound", value: "FKP" },
  { label: "Fijian dollar", value: "FJD" },
  { label: "CFP franc", value: "XPF" },
  { label: "Gambian dalasi", value: "GMD" },
  { label: "Georgian lari", value: "GEL" },
  { label: "Ghanaian cedi", value: "GHS" },
  { label: "Gibraltar pound", value: "GIP" },
  { label: "Guatemalan quetzal", value: "GTQ" },
  { label: "Guernsey Pound", value: "GGP" },
  { label: "Guinean franc", value: "GNF" },
  { label: "Guyanese dollar", value: "GYD" },
  { label: "Haitian gourde", value: "HTG" },
  { label: "Honduran lempira", value: "HNL" },
  { label: "Hong Kong dollar", value: "HKD" },
  { label: "Hungarian forint", value: "HUF" },
  { label: "Icelandic krona", value: "ISK" },
  { label: "Indian rupee", value: "INR" },
  { label: "Indonesian rupiah", value: "IDR" },
  { label: "SDR (Special Drawing Right)", value: "XDR" },
  { label: "Iranian rial", value: "IRR" },
  { label: "Iraqi dinar", value: "IQD" },
  { label: "Manx pound", value: "IMP" },
  { label: "Israeli new shekel", value: "ILS" },
  { label: "Jamaican dollar", value: "JMD" },
  { label: "Japanese yen", value: "JPY" },
  { label: "Jersey pound", value: "JEP" },
  { label: "Jordanian dinar", value: "JOD" },
  { label: "Kazakhstani tenge", value: "KZT" },
  { label: "Kenyan shilling", value: "KES" },
  { label: "Kuwaiti dinar", value: "KWD" },
  { label: "Kyrgyzstani som", value: "KGS" },
  { label: "Lao kip", value: "LAK" },
  { label: "Lebanese pound", value: "LBP" },
  { label: "Lesotho loti", value: "LSL" },
  { label: "Liberian dollar", value: "LRD" },
  { label: "Libyan dinar", value: "LYD" },
  { label: "Swiss franc", value: "CHF" },
  { label: "Macanese pataca", value: "MOP" },
  { label: "Malagasy ariary", value: "MGA" },
  { label: "Malawian kwacha", value: "MWK" },
  { label: "Malaysian ringgit", value: "MYR" },
  { label: "Maldivian rufiyaa", value: "MVR" },
  { label: "Mauritanian ouguiya", value: "MRU" },
  { label: "Mauritian rupee", value: "MUR" },
  { label: "Mexican peso", value: "MXN" },
  { label: "Moldovan leu", value: "MDL" },
  { label: "Mongolian tugrik", value: "MNT" },
  { label: "Moroccan dirham", value: "MAD" },
  { label: "Mozambican metical", value: "MZN" },
  { label: "Myanmar kyat", value: "MMK" },
  { label: "Namibian dollar", value: "NAD" },
  { label: "Nepalese rupee", value: "NPR" },
  { label: "Nicaraguan cordoba", value: "NIO" },
  { label: "Nigerian naira", value: "NGN" },
  { label: "North Korean won", value: "KPW" },
  { label: "Macedonian denar", value: "MKD" },
  { label: "Norwegian krone", value: "NOK" },
  { label: "Omani rial", value: "OMR" },
  { label: "Pakistani rupee", value: "PKR" },
  { label: "Papua New Guinean kina", value: "PGK" },
  { label: "Paraguayan guarani", value: "PYG" },
  { label: "Peruvian sol", value: "PEN" },
  { label: "Philippine peso", value: "PHP" },
  { label: "Polish zloty", value: "PLN" },
  { label: "Qatari riyal", value: "QAR" },
  { label: "Romanian leu", value: "RON" },
  { label: "Russian ruble", value: "RUB" },
  { label: "Rwandan franc", value: "RWF" },
  { label: "Samoan tala", value: "WST" },
  { label: "Sao Tome and Principe dobra", value: "STN" },
  { label: "Saudi Arabian riyal", value: "SAR" },
  { label: "Serbian dinar", value: "RSD" },
  { label: "Seychellois rupee", value: "SCR" },
  { label: "Sierra Leonean leone", value: "SLL" },
  { label: "Singapore dollar", value: "SGD" },
  { label: "Solomon Islands dollar", value: "SBD" },
  { label: "Somali shilling", value: "SOS" },
  { label: "South African rand", value: "ZAR" },
  { label: "Pound sterling", value: "GBP" },
  { label: "South Korean won", value: "KRW" },
  { label: "South Sudanese pound", value: "SSP" },
  { label: "Sri Lankan rupee", value: "LKR" },
  { label: "Sudanese pound", value: "SDG" },
  { label: "Surinamese dollar", value: "SRD" },
  { label: "Swedish krona", value: "SEK" },
  { label: "Syrian pound", value: "SYP" },
  { label: "New Taiwan dollar", value: "TWD" },
  { label: "Tajikistani somoni", value: "TJS" },
  { label: "Tanzanian shilling", value: "TZS" },
  { label: "Thai baht", value: "THB" },
  { label: "Tongan pa’anga", value: "TOP" },
  { label: "Trinidad and Tobago dollar", value: "TTD" },
  { label: "Tunisian dinar", value: "TND" },
  { label: "Turkish lira", value: "TRY" },
  { label: "Turkmen manat", value: "TMT" },
  { label: "Ugandan shilling", value: "UGX" },
  { label: "Ukrainian hryvnia", value: "UAH" },
  { label: "UAE dirham", value: "AED" },
  { label: "Uruguayan peso", value: "UYU" },
  { label: "Uzbekistani som", value: "UZS" },
  { label: "Vanuatu vatu", value: "VUV" },
  { label: "Venezuelan bolivar", value: "VES" },
  { label: "Vietnamese dong", value: "VND" },
  { label: "Yemeni rial", value: "YER" },
  { label: "Zambian kwacha", value: "ZMW" },
];

export const TOUR_TYPE_OPTIONS = [
  { label: "Classical tour", value: "Classical tour" },
  { label: "Fun tour", value: "Fun tour" },
  { label: "Thematic tour", value: "Thematic tour" },
  { label: "Gastro tour", value: "Gastro tour" },
  { label: "Nature tour", value: "Nature tour" },
  { label: "Foto tour", value: "Foto tour" },
  { label: "Museum tour", value: "Museum tour" },
  { label: "Other", value: "Other" },
];
