import React, { useState, useMemo, useCallback } from "react";
import TourDetails from '../TourDetails/TourDetails';
import { useNavigate } from 'react-router-dom';

import './style/tourCards.css';

import StarRating from '../TourDetails/StarRating';

import tour1 from '../../assets/Home/tour1.png'
import tour2 from '../../assets/Home/tour2.png'
import tour3 from '../../assets/Home/tour3.png'
import tour4 from '../../assets/Home/tour4.png'
import tour5 from '../../assets/Home/tour5.png'
import tour6 from '../../assets/Home/tour6.png'
import tour7 from '../../assets/Home/tour7.png'
import tour8 from '../../assets/Home/tour8.png'
import tour9 from '../../assets/Home/tour9.png'

function TourCards({tours,latestData}){
    const navigate = useNavigate();

    const [selectedTour, setSelectedTour] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toursArray = useMemo(() => {
        return Object.values(tours).map(tour => JSON.parse(tour.tourData));
    }, [tours]);

    const getNumberOfTours = useCallback((size) => {
        if (size < 3) return 0;
        if (size >= 3 && size <= 5) return 3;
        if (size >= 6 && size <= 8) return 6;
        return 9;
    }, []);

    const numberOfToursToShow = useMemo(() => getNumberOfTours(toursArray.length), [toursArray.length, getNumberOfTours]);
    const toursToShow = useMemo(() => toursArray.slice(0, numberOfToursToShow), [toursArray, numberOfToursToShow]);


    const handleTourClick = (tour) => {
        /*setSelectedTour(tour);
        setIsModalOpen(true);*/
        navigate(`/tour/${tour.tourId}/${tour.tourGuideId}`);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedTour(null);
    };

    const convertDistance = (meters) => {
        if(!meters){
            return null;
        }
        if(meters < 1000){
            return meters.toFixed(1) + " m"; 
        }
        return (meters / 1000).toFixed(2) + " km";  
    };
  
    const convertTime = (seconds) => {
        if(!seconds){
          return null;
        }
        const minutes = (seconds / 60).toFixed(2);  
  
        if (minutes < 60){
          return parseFloat(minutes).toFixed(0) + " min"
        } else {
          return (minutes / 60).toFixed(2) + " h";  
        }
  
    };
  
    const extractLanguage = (languageString) => {
        if(!languageString){
          return null;
        }
  
        const match = languageString.match(/^([^\[]+)/);
        const language = match ? match[1].trim() : languageString;
        return language.charAt(0).toUpperCase() + language.slice(1).toLowerCase();
    };

    const TourCard = React.memo((props) => {
        //TODO: refactor this updation of tour details data
        const originalProps = props;

        const latestDataLoaded = latestData && Object.keys(latestData).length > 0 && props.tourId in latestData;
        if(latestDataLoaded){
            const tourId = props.tourId;
            props = latestData[props.tourId]?.details;
            props.tourId = tourId;
        } 
        //END TODO

        const imgUrl = originalProps.picturesURLs[0] ? originalProps.picturesURLs[0]["link"] : ""

        const reviews = latestData[originalProps.tourId]?.reviews;

        return <div className="tour_card" onClick={()=>{handleTourClick(props)}}>
            <img src={imgUrl} alt={props.label} />
            <div className="card_body">
                <div className="card_header">
                    <div className="card_title">
                        <div className="tour_name">
                            {props.name}
                        </div>
                        <div className="tour_location">
                            {props.tourCity}, {props.tourCountry}
                        </div>
                        {latestDataLoaded && <div className="tour_price">
                            {props.tourCurrency} {props.tourPrice}
                        </div>}
                    </div>
                    {reviews?.averageRating && (
                        <div className='tour_rating'>
                            <StarRating rating={reviews?.averageRating} isAlt={true}/>
                            <p className='average_rating'>{reviews?.averageRating}</p>
                            {reviews?.reviewCount && <p>({reviews?.reviewCount} ratings)</p>}
                        </div>
                    )}
                </div>
                {latestDataLoaded && <div className="tour_specs">
                    <div>
                        <img className="spec_icon" src="/images/type.svg"/>
                        <div>Type: <span className="bolded_text"> {props.tourType} </span></div>
                    </div>
                    <div>
                        <img className="spec_icon" src="/images/distance.svg"/>
                        <div>Distance: <span className="bolded_text"> {convertDistance(props.tourDistance)} </span></div>
                    </div>
                    <div>
                        <img className="spec_icon" src="/images/duration.svg"/>
                        <div>Duration: <span className="bolded_text"> {convertTime(props.tourDuration)} </span></div>
                    </div>
                    <div>
                        <img className="spec_icon" src="/images/globe.svg"/>
                        <div>Language: <span className="bolded_text"> {extractLanguage(props.tourLanguage)} </span></div>
                    </div>
                </div>}
                {!latestDataLoaded && <div><div className="spinner"/></div>}
            </div>
            <div className="hoverE">
            </div>
        </div>
    });

    return(
        <div>
            <div className="cards">
                {
                    toursToShow.map((tour, i) =><TourCard key={`k_${i}`} {...tour} onClick={() => handleTourClick(tour)}/>)                    
                }
                {isModalOpen 
                    && <TourDetails 
                        latestTourData={latestData[selectedTour.tourId]?.details}
                        reviews={latestData[selectedTour.tourId]?.reviews}
                        tourGuideInfo={latestData[selectedTour.tourId]?.tourGuide}
                        onClose={handleCloseModal} />}
            </div>
        </div>
    )
}

export default TourCards;