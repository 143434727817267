import React from 'react';
import './style/guideInfo.css';
import StarRating from './StarRating';

const GuideInfo = ({ guide }) => {
    if (!guide) return null;

    const { profilePictureURL, firstName, lastName, biography, location, occupation, specialty, tourismExperience, rating } = guide;

    return (
        <div className="guide-info">
            <div className="guide-profile">
                <img src={profilePictureURL} alt={`${firstName} ${lastName}`} className="guide-profile-picture" />
                <div className="guide-details">
                    <div className='tour-guide-heading'> 
                        <h4>{firstName} {lastName}</h4> 
                        {rating && (
                                <div className='tour-rating'>
                                    <StarRating rating={rating.average} />
                                    <p className='average-rating'>{rating.average.toFixed(1)}</p>
                                    <p className='num-ratings'>({rating.count} ratings)</p>
                                </div>
                            )}
                    </div>
                    {biography && <div className='guide-bio'><strong>Biography:</strong><p>{biography}</p></div>}
                </div>
            </div>
        </div>
    );
};

export default GuideInfo;
