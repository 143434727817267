import React, { useState, useEffect, useRef } from 'react'
import { Routes as DomRoutes, Route, useLocation } from 'react-router'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import './routes.css'

import HomeTourist from '../Components/Home/HomeTourist'
import HomeTourGuide from '../Components/Home/HomeTourGuide'
import TourDetails from '../Components/TourDetails/TourDetails'

const Routes = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [location.pathname])

  return (
    <div>
      <TransitionGroup component="div">
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <DomRoutes location={location}>
            <Route exact path="/" element={<HomeTourist />} />
            <Route path="/tour/:tourId/:tourGuideId" element={<TourDetails />} />
            <Route exact path="/guide" element={<HomeTourGuide />} />
            <Route path="*" element={<HomeTourist />} />
          </DomRoutes>
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
}

export default Routes
