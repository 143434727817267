import Bimg from '../../assets/Home/Bimg.png'

import './styles/feature.css'
import './styles/mobile/feature.css'

function Feature({
  heading,
  features = {},
  isGuide,
  isCustom,
  hasButton,
  websiteText,
}) {
  let formattedFeatures = Object.values(features)

  const formatHeading= (text) => {
    if(text == "INSIDARTOURS - BUILT WITH YOU IN MIND"){
      return <> <span className="blue_simple"> INSIDARTOURS </span> <span className='blackText'> - BUILT WITH YOU IN MIND </span> </>
    }else if(text == "PROFESSIONAL TOUR GUIDES:"){
      return <span className='blackText'> PROFESSIONAL TOUR GUIDES: </span>
    }else{
      return text
    }
  } 

  return (
    <div id="TourFeatures">
      <div className="AdvertH FeaturesH">
        <h1>{formatHeading(heading)}</h1>
      </div>

      <div className="Features">
        {formattedFeatures.map((feature, i) => (
          <div key={`feature_${i}`} className="FeatureCard">
            <div>
              <img src={feature['feature_img']} alt="" />
            </div>
            <div>
              <div className="FCardH">
                <span>{feature['feature_title']}</span>
              </div>
              <div className="FCardP">
                <span>{feature['feature_para']}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      {hasButton ? (
        <div className="features_btn">
          <button>Try TourBuilder</button>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Feature
