import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLoaderDialog } from '../../contexts/LoaderDialogContext'
import axios from 'axios'

import NavHome from './HomeNav'

import StarRating from '../TourDetails/StarRating';

import Feature from './Feature'
import Footer from '../Footer/Footer'
import TourCards from '../TourCards/TourCards'
import TourDetails from '../TourDetails/TourDetails'

import bgMapImage from '../../assets/imgs/bgMap.png';

import {
  FIREBASE_API_KEY,
  READ_WEBSITE_DATA,
  READ_TOUR_DATA,
  READ_TOUR_REVIEWS,
  READ_GUIDE_DATA,
  READ_AVAILABLE_LANGUAGE,
  READ_LANGUAGE,
} from '../../constants'

import overlay from '../../assets/Home/overlayimg.png'
import phone from '../../assets/Home/phone.png'
import apple from '../../assets/Home/apple.png'
import playstore from '../../assets/Home/playstore.png'
import cImg from '../../assets/Home/cImg.png'
import Bimg from '../../assets/Home/Bimg.png'
import arrow from '../../assets/Home/arrow.png'
import phone2 from '../../assets/Home/phone2.png'
import TrustedToursImg from '../../assets/Home/tours_trust.png'
import ExpImg01 from '../../assets/Home/exp_01.png'
import ExpImg02 from '../../assets/Home/exp_02.png'
import ExpImg03 from '../../assets/Home/exp_03.png'
import ExpImg04 from '../../assets/Home/exp_04.png'
import ExpImg05 from '../../assets/Home/exp_05.png'

import './styles/dashboard.css'
import './styles/tourist.css'
import './styles/mobile/tourist.css'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

import tour1 from '../../assets/Home/tour2.png'
import tour2 from '../../assets/Home/tour3.png'
import tour3 from '../../assets/Home/tour4.png'

import leftChevron from '../../assets/icons/leftChevron.svg'
import rightChevron from '../../assets/icons/rightChevron.svg'

function HomeTourist() {
  const location = useLocation()
  const navigate = useNavigate()
  const { isLoading, showLoader, hideLoader } = useLoaderDialog()

  const [isNavbarSolid, setNavbarSolid] = useState(false)
  const [websiteContent, setWebsiteContent] = useState({})
  const [language, setLanguage] = useState('default')
  const [availableLanguages, setAvailableLanguages] = useState([])

  const homeRef = useRef(null)
  const aboutUsRef = useRef(null)
  const mobileAboutUsRef = useRef(null)
  const toursRef = useRef(null)
  const mobileToursRef = useRef(null)
  const contactUsRef = useRef(null)

  useEffect(() => {
    if (!isLoading && location.state && location.state.scrollToTours) {
      toursRef?.current?.scrollIntoView({ behavior: 'smooth' });
      mobileToursRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location,isLoading]);

  useEffect(() => {
    let currentlanguage = localStorage.getItem('lang')
    if (currentlanguage != undefined) {
      setLanguage(currentlanguage)
    }
  }, [])

  useEffect(() => {
    //Api for getting available languages
    axios
      .get(READ_AVAILABLE_LANGUAGE, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          userType: 'tourist',
        },
      })
      .then((response) => {
        if (response.status == 200) {
          let respD = response.data.data.home.tourist
          setAvailableLanguages([...respD, 'default'])
        }
      })

    axios
      .get(READ_WEBSITE_DATA, {
        params: {
          userType: 'tourist',
          project: 'home',
          filtered: 'false',
          code: language,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        if (res.status == 200) {
          let updatedWebsiteContent = res.data.data

          setWebsiteContent(updatedWebsiteContent)

          hideLoader()
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }, [language])

  const getIntro = (text) => {
    if(text == "EXPLORE YOUR WAY WITH INTERACTIVE AUDIO TOURS"){
      return <> EXPLORE <span className="blue_slanted"> YOUR WAY </span> WITH INTERACTIVE AUDIO TOURS </>
    }else{
      return text
    }
  } 

  const getFeaturesHeading = (text) => {
    if(text == "KEY FEATURES AND BENEFITS OF INSIDARTOURS"){
      return <> <span className="normal_text"> KEY </span> <span className="blue_text"> FEATURES AND BENEFITS </span> <span className="normal_text"> OF INSIDARTOURS </span> </>
    }else{
      return text
    }
  }

  const advertText2 = "Start whenever your want, 24/7, and explore at your own pace with self-guided tours.;;Finish your tour the next day or next time you are visiting. Every tour you purchase is yours to keep.";
  const advertText3 = `Read verified reviews and select the tours with the best ratings.;;"Like" tours and save them to favorites, leave feedback and flag  inappropriate, incorrect, or incomplete tours.`;

  //TODO: Centralize this appropriately
  const [selectedTour, setSelectedTour] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allToursLatestData, setAllToursLatestData] = useState({});
  const [allReviews, setAllReviews] = useState({});
  const [allTourGuides, setAllTourGuides] = useState({});
  const [latestData, setLatestData] = useState({});

  let tours = useMemo(() => websiteContent["tours"], [websiteContent]);
  let toursArray = []

  if (tours){
    toursArray = Object.values(tours).map(tour => JSON.parse(tour.tourData));

    const getNumberOfTours = (size) => {
        if (size < 3) return 0;
        if (size >= 3 && size <= 5) return 3;
        if (size >= 6 && size <= 8) return 6;
        return 9;
    };

    const numberOfToursToShow = getNumberOfTours(toursArray.length);
    toursArray = toursArray.slice(0, numberOfToursToShow);
  }

  const handleTourClick = (tour) => {
      //setSelectedTour(tour);
      //setIsModalOpen(true);
      navigate(`/tour/${tour.tourId}/${tour.tourGuideId}`);
  };

  const handleCloseTourDetails = () => {
      setIsModalOpen(false);
      setSelectedTour(null);
  };

  useEffect(()=>{
    if (tours){
      toursArray = Object.values(tours).map(tour => JSON.parse(tour.tourData));
  
      const getNumberOfTours = (size) => {
          if (size < 3) return 0;
          if (size >= 3 && size <= 5) return 3;
          if (size >= 6 && size <= 8) return 6;
          return 9;
      };
  
      const numberOfToursToShow = getNumberOfTours(toursArray.length);
      toursArray = toursArray.slice(0, numberOfToursToShow);
    }else{
      return;
    }

    const fetchData = async () => {
      const tourRequests = toursArray.map((tour) => {
        return Promise.all([
          axios.get(READ_TOUR_DATA, {
            headers: { 'Content-Type': 'application/json' },
            params: { tourId: tour.tourId },
          }),
          axios.get(READ_TOUR_REVIEWS, {
            headers: { 'Content-Type': 'application/json' },
            params: { tourId: tour.tourId },
          }),
          axios.get(READ_GUIDE_DATA, {
            headers: { 'Content-Type': 'application/json' },
            params: { uid: tour.tourGuideId },
          }),
        ]);
      });

      const tourResponses = await Promise.all(tourRequests);

      const newLatestData = {};

      tourResponses.forEach(([tourRes, reviewsRes, guideRes], index) => {
        const tourId = toursArray[index].tourId;
        newLatestData[tourId] = {};

        if (tourRes.status === 200) {
          const tour = tourRes.data.data;
          newLatestData[tourId].details = tour.details;
        }

        if (reviewsRes.status === 200) {
          const reviewsData = reviewsRes.data.data.reviews;
          const totalRating = reviewsData.reduce((sum, review) => sum + parseFloat(review.rating), 0);
          const avgRating = totalRating / reviewsData.length;

          newLatestData[tourId].reviews = {
            averageRating: avgRating.toFixed(1),
            reviewCount: reviewsData.length,
          };
        }

        if (guideRes.status === 200) {
          const tourGuide = guideRes.data.data;
          newLatestData[tourId].tourGuide = tourGuide;
        }
      });

      setLatestData(newLatestData);
    };

    fetchData();
  },[tours])
  //END TODO


  const convertDistance = (meters) => {
    if(!meters){
        return null;
    }
    if(meters < 1000){
        return meters.toFixed(1) + " m"; 
    }
    return (meters / 1000).toFixed(2) + " km";  
  };

  const convertTime = (seconds) => {
    if(!seconds){
      return null;
    }
    const minutes = (seconds / 60).toFixed(2);  

    if (minutes < 60){
      return parseFloat(minutes).toFixed(0) + " min"
    } else {
      return (minutes / 60).toFixed(2) + " h";  
    }
  };

  const extractLanguage = (languageString) => {
    if(!languageString){
      return null;
    }

    const match = languageString.match(/^([^\[]+)/);
    const language = match ? match[1].trim() : languageString;
    return language.charAt(0).toUpperCase() + language.slice(1).toLowerCase();
  };
  
  const extractType = (tourType) => {
    if(tourType == null){
      return null;
    }

    if(tourType.endsWith(" tour")){
      return tourType.slice(0, -5);
    }

    return tourType;
  }


  if (isLoading) {
    return <></>
  }

  return (
    <div id="HomePage">
      <div
        className="hero_section"
        style={{
          backgroundImage: `url(${websiteContent['pictures']['hero_bg']['pictureURL']})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100%',
        }}
      >
        <NavHome
          isSolid={isNavbarSolid}
          language={language}
          setLanguage={setLanguage}
          languagesList={availableLanguages}
          sectionRefs={[homeRef, aboutUsRef, toursRef, contactUsRef, mobileAboutUsRef, mobileToursRef]}
        />
        <div ref={homeRef} id="HomeIntro">
          <button
            className="nav_switch_btn navhome_buttons_for_mobile dark_background"
            onClick={() => {
              showLoader()
              navigate(location.pathname.includes('guide') ? '/' : '/guide')
            }}
          >
            SWITCH TO GUIDE
          </button>

          <div className="Intro">
            {/*<h1>{websiteContent['text']['hero_title']['text']}</h1>*/}
            <h1>{getIntro(websiteContent['text']['hero_title']['text'])} </h1>
          </div>

          <div className="desc">
            <p>{websiteContent['text']['hero_para']['text']}</p>
          </div>

          <div className="downloadbtn">
            <button>DOWNLOAD APP</button>
          </div>
        </div>
      </div>

      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}>
        <div
          className="background-overlay"
          style={{
            position: 'absolute',
            top: '0', // Adjust to spill 20% above
            left: '0',
            right: '0',
            bottom: '0',
            backgroundImage: `linear-gradient(to bottom, rgba(232, 232, 232, 1) 0%, rgba(232, 232, 232, 0) 100%), url(${bgMapImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '160% 75%',
            backgroundPosition: 'bottom',
            opacity: '0.7',
            zIndex: '-1',
          }}
        ></div>
        <div ref={aboutUsRef} className="guidance">
          <div
            className="left-guide"
            style={{
              background: `linear-gradient(180deg, #001f2b 0%, rgba(0, 31, 43, 0) 100%), url(${websiteContent['pictures']['card_1_img']['pictureURL']}) no-repeat center`,
              backgroundSize: 'cover',
            }}
          >
            <div>{<h2 className='orangeText'>{websiteContent['text']['card_1_title']['text']}</h2>}</div>
            <div>
              {<span>{websiteContent['text']['card_1_paragraph']['text']}</span>}
              <div 
                className='orangeLink'
                onClick={()=>{
                  toursRef.current.scrollIntoView();
                }}> 
                Browse Tours → 
              </div>
            </div>
          </div>
          <div
            className="right-guide"
            style={{
              background: `linear-gradient(0deg, #001f2b 0%, rgba(0, 31, 43, 0) 100%), url(${websiteContent['pictures']['card_2_img']['pictureURL']}) no-repeat center`,
              backgroundSize: 'cover',
            }}
          >
            <div>{<h2 className='orangeText'>{websiteContent['text']['card_2_title']['text']}</h2>}</div>
            <div>
              {<span>{websiteContent['text']['card_2_paragraph']['text']}</span>}
              <div 
                className='orangeLink'
                onClick={()=>{
                  showLoader();
                  navigate(location.pathname.includes('guide') ? '/' : '/guide');
                }}> 
                Become a Guide → 
              </div>
            </div>
          </div>
        </div>

        <div className="Advert"
          /*style={{
            background: `linear-gradient(0deg, #e8e8e8 0%, rgba(232, 232, 232, 0) 100%), url(${bgMapImage}) no-repeat center`,
            backgroundSize: 'cover',
          }}*/
          
        >
          
          <div className="AdvertInfo">
            <div className="AdvertH">
              <img src="/images/blueQuotes.svg" />
              <h1>{websiteContent['text']['advertise_1_title']['text']}</h1>
            </div>
            {websiteContent['text']['advertise_1_para']['text'].split(";;").map((portion)=>{
              return <div className="AdvertP">
                {portion}
              </div>
            })}
            <div className="AdvertBtn">
              <div>
                <button>
                  <div>
                    <img src={apple} alt="appleLogo" />
                  </div>
                  <div>
                    {' '}
                    <div> DOWNLOAD ON THE</div>
                    <div className="bold">
                      {' '}
                      <span> APP STORE </span>
                    </div>
                  </div>
                </button>
              </div>

              <div>
                <button>
                  <div>
                    <img src={playstore} alt="playstore" />
                  </div>
                  <div className="playbtn">
                    <div>GET IT ON</div>
                    <div className="bold">
                      <span>GOOGLE PLAY</span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div className="AdvertImg">
            <img
              src={websiteContent['pictures']['advertise_1_img']['pictureURL']}
              alt="phoneImg"
            />
          </div>

          <div className="AdvertBtn advert_btn_for_mobile">
            <div>
              <button>
                <div>
                  <img src={apple} alt="appleLogo" />
                </div>
                <div>
                  {' '}
                  <div> DOWNLOAD ON THE</div>
                  <div className="bold">
                    {' '}
                    <span> APP STORE </span>
                  </div>
                </div>
              </button>
            </div>

            <div>
              <button>
                <div>
                  <img src={playstore} alt="playstore" />
                </div>
                <div className="playbtn">
                  <div>GET IT ON</div>
                  <div className="bold">
                    <span>GOOGLE PLAY</span>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div ref={toursRef} className="TourCards">
        <TourCards 
          tours={tours ?? []}
          latestData={latestData}
        />
      </div>

      <div ref={mobileToursRef} className="tour_cards_carousel">
        <Carousel 
          showArrows={true} 
          showThumbs={false} 
          showStatus={false}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            return <div
              style={{
                width: '10px',
                height: '10px',
                color: 'transparent',
                display: 'inline-block',
                margin: '4px',
                borderRadius: '50%',  
                backgroundColor: isSelected ? '#0378A6' : '#EEEEEE',  
              }}
            >.</div>
          }}
          renderArrowNext={(clickHandler, hasNext) => {
            const arrowStyles = {
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 4,
              display: hasNext ? 'flex' : 'none',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '3px',
              opacity: '0.9',
              cursor: 'pointer',
              zIndex: '20',
            };
          
            return (
              <div style={arrowStyles} onClick={clickHandler}>
                <div style={{
                  width: "40px",
                  height: "40px",
                  background: "#EEEEEE",
                  display: "flex",
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                }}>
                  <img src={rightChevron} alt="Right Chevron" width={12} height={12}/>
                </div>
              </div>
            );
          }}

          renderArrowPrev={(clickHandler, hasPrev) => {
            const arrowStyles = {
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 4, 
              display: hasPrev ? 'flex' : 'none',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '3px',
              opacity: '0.9',
              cursor: 'pointer',
              zIndex: '20',
            };
          
            return (
              <div style={arrowStyles} onClick={clickHandler}>
                <div style={{
                  width: "40px",
                  height: "40px",
                  background: "#EEEEEE",
                  display: "flex",
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                }}>
                  <img src={leftChevron} alt="Left Chevron" width={12} height={12} />
                </div>
              </div>
            );
          }}
          >
          {toursArray.map((tour,i)=>{
            const imgUrl = tour.picturesURLs[0] ? tour.picturesURLs[0]["link"] : ""
            const reviews = latestData[tour.tourId]?.reviews || null;
            const language = latestData[tour.tourId]?.details?.tourLanguage

            return <div key={"tour_"+i} className="carouselImg" onClick={() => handleTourClick(tour)}>
                <img src={imgUrl} alt={"tour_"+i} />
                <div className="card_body">
                    <div className="card_header">
                        <div className="card_title">
                            <div className="tour_name">
                                {tour.name}
                            </div>
                            <div className="tour_location">
                                {tour.tourCity}, {tour.tourCountry}
                            </div>
                            <div className="tour_price">
                                {tour.tourCurrency} {tour.tourPrice}
                            </div>
                        </div>
                        {reviews?.averageRating && (
                            <div className='tour_rating'>
                                <StarRating rating={reviews?.averageRating} isAlt={true}/>
                                <p className='average_rating'>{reviews?.averageRating}</p>
                                {reviews?.reviewCount && <p>({reviews?.reviewCount} ratings)</p>}
                            </div>
                        )}
                    </div>
                    {reviews && <div className="tour_specs">
                      <div>
                        <div className='half'>
                            <img className="spec_icon" src="/images/type.svg"/>
                            <span className="bolded_text"> {extractType(tour.tourType)} </span>
                        </div>
                        <div className='half'>
                            <img className="spec_icon" src="/images/distance.svg"/>
                            <span className="bolded_text"> {convertDistance(tour.tourDistance)} </span>
                        </div>
                      </div>
                      <div>
                        <div className='half'>
                            <img className="spec_icon" src="/images/duration.svg"/>
                            <span className="bolded_text"> {convertTime(tour.tourDuration)} </span>
                        </div>
                        <div className='half'>
                            <img className="spec_icon" src="/images/globe.svg"/>
                            <span className="bolded_text"> {extractLanguage(language)} </span>
                        </div>
                      </div>
                    </div>}
                </div>
            </div>
          })}
        </Carousel>
      </div>

      <div ref={mobileAboutUsRef}>
        <h1 className='ProcessH blackText'>START EXPLORING IN MINUTES </h1>
        <div className="ProcessPage">
          
          <div className="innerBorder"></div>

          <div className="Process">
            {[1, 2, 3, 4, 5].map((index) => (
              <React.Fragment key={`flow_${index}`}>
                <div className="ProcessCard">
                  <div>
                    <img
                      src={
                        websiteContent['pictures'][`flow_${index}_img`][
                          'pictureURL'
                        ]
                      }
                      alt="Img"
                    />
                  </div>
                  <div className="PCardH">
                    <span>
                      {websiteContent['text'][`flow_${index}_title`]['text']}
                    </span>
                  </div>
                  <div className="PCardP">
                    <span>
                      {websiteContent['text'][`flow_${index}_para`]['text']}
                    </span>
                  </div>
                </div>

                {index < 5 ? (
                  <div className="arrow">
                    <img src={arrow} alt="arrow" />
                  </div>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <div className="centerDown">
        <div className="downloadbtn">
          <button
            onClick={()=>{
              toursRef.current.scrollIntoView()
            }}>
            DISCOVER MORE - FOR LESS!
          </button>
        </div>
      </div>
      
      <div className="AdvertSecond"
          /*style={{
            background: `linear-gradient(0deg, #e8e8e8 0%, rgba(232, 232, 232, 0) 100%), url(${bgMapImage}) no-repeat center`,
            backgroundSize: 'cover',
          }}*/
          
        >
          
        {/* TODO: Make these editable from the Admin Dashboard */}
        <div className="AdvertInfo">
          <div className="AdvertH">
            <img src="/images/blueQuotes.svg" />
            <h1>TOUR ON YOUR SCHEDULE</h1>
          </div>
          {advertText2.split(";;").map((portion)=>{
            return <div className="AdvertP">
              {portion}
            </div>
          })}
          <div className="AdvertBtn">
            <div>
              <button>
                <div>
                  <img src={apple} alt="appleLogo" />
                </div>
                <div>
                  {' '}
                  <div> DOWNLOAD ON THE</div>
                  <div className="bold">
                    {' '}
                    <span> APP STORE </span>
                  </div>
                </div>
              </button>
            </div>

            <div>
              <button>
                <div>
                  <img src={playstore} alt="playstore" />
                </div>
                <div className="playbtn">
                  <div>GET IT ON</div>
                  <div className="bold">
                    <span>GOOGLE PLAY</span>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="AdvertImg">
          <img
            src={websiteContent['pictures']['advertise_1_img']['pictureURL']}
            alt="phoneImg"
          />
        </div>

        <div className="AdvertBtn advert_btn_for_mobile">
          <div>
            <button>
              <div>
                <img src={apple} alt="appleLogo" />
              </div>
              <div>
                {' '}
                <div> DOWNLOAD ON THE</div>
                <div className="bold">
                  {' '}
                  <span> APP STORE </span>
                </div>
              </div>
            </button>
          </div>

          <div>
            <button>
              <div>
                <img src={playstore} alt="playstore" />
              </div>
              <div className="playbtn">
                <div>GET IT ON</div>
                <div className="bold">
                  <span>GOOGLE PLAY</span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      
      <div
        style={{
            position: 'relative',
            overflow: 'hidden',
        }}>
        <div
          className="background-overlay"
          style={{
            position: 'absolute',
            top: '0', // Adjust to spill 20% above
            left: '0',
            right: '0',
            bottom: '0',
            backgroundImage: `linear-gradient(to bottom, rgba(232, 232, 232, 1) 0%, rgba(232, 232, 232, 0) 100%), url(${bgMapImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '160% 60%',
            backgroundPosition: 'bottom',
            opacity: '0.7',
            zIndex: '-1',
          }}
        ></div>
        <div>
          <Feature
            heading={getFeaturesHeading(websiteContent['text']['features_title']['text'])}
            features={websiteContent['features']}
            isGuide={false}
          />
        </div>
        <div>
          <div className="Advert">
            <div className="AdvertInfo">
              <div className="AdvertH">
                <img src="/images/blueQuotes.svg" />
                <h2>
                  {websiteContent['text']['advertise_2_title']['text']}
                  <br />
                </h2>
              </div>
              <div className="AdvertP">
                {websiteContent['text']['advertise_2_para']['text']}
              </div>
              <div className="AdvertBtn">
                <div>
                  <button>
                    <div>
                      <img src={apple} alt="appleLogo" />
                    </div>
                    <div>
                      {' '}
                      <div> DOWNLOAD ON THE</div>
                      <div className="bold">
                        {' '}
                        <span> APP STORE </span>
                      </div>
                    </div>
                  </button>
                </div>

                <div>
                  <button>
                    <div>
                      <img src={playstore} alt="playstore" />
                    </div>
                    <div className="playbtn">
                      <div>GET IT ON</div>
                      <div className="bold">
                        <span>GOOGLE PLAY</span>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>

            <div className="AdvertImg">
              <img
                src={websiteContent['pictures']['advertise_2_img']['pictureURL']}
                alt="phoneImg"
              />
            </div>

            <div className="AdvertBtn advert_btn_for_mobile">
              <div>
                <button>
                  <div>
                    <img src={apple} alt="appleLogo" />
                  </div>
                  <div>
                    {' '}
                    <div> DOWNLOAD ON THE</div>
                    <div className="bold">
                      {' '}
                      <span> APP STORE </span>
                    </div>
                  </div>
                </button>
              </div>

              <div>
                <button>
                  <div>
                    <img src={playstore} alt="playstore" />
                  </div>
                  <div className="playbtn">
                    <div>GET IT ON</div>
                    <div className="bold">
                      <span>GOOGLE PLAY</span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* TODO: Make these editable from the Admin Dashboard */}
        <div>
          <div className="AdvertAlt">
            <div className="AdvertImg">
              <img
                src="/images/personUsingPhone.svg"
                alt="person using their phone"
              />
            </div>
            <div className="AdvertInfoAlt">
              <div className="AdvertH">
                <img src="/images/blueQuotes.svg" />
                <h2>
                  Tours You Can Trust
                  <br />
                </h2>
              </div>
              {advertText3.split(";;").map((portion)=>{
                return <div className="AdvertP">
                  {portion}
                </div>
              })}
              <div className="AdvertBtnAlt">
                <button
                  onClick={()=>{
                    aboutUsRef.current.scrollIntoView()
                  }}>
                  ABOUT US
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>{isModalOpen 
            && <TourDetails 
                latestTourData={latestData[selectedTour.tourId]?.details}
                reviews={latestData[selectedTour.tourId]?.reviews}
                tourGuideInfo={latestData[selectedTour.tourId]?.tourGuide}
                toursRef={toursRef}
                onClose={handleCloseTourDetails} 
              />}
      </div>

      <div ref={contactUsRef} className="Footer">
        <Footer sectionRefs={[aboutUsRef, toursRef]}/>
      </div>
    </div>
  )
}

export default HomeTourist
