import React, { useRef, useState, useEffect } from 'react';
import './style/customAudio.css';

import axios from 'axios';
import { Howl, Howler } from 'howler';


const CustomAudio = ({ src, isHidden }) => {
    const audioRef = useRef(null);
    const seekbarRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [audioSrc, setAudioSrc] = useState(null);

    useEffect(() => {
        if(isHidden){
            audioSrc.pause();
        }
    },[isHidden])

    useEffect(() => {
        const fetchAndConvertAudio = async () => {
            try {
                const response = await axios.get(src, {
                    responseType: 'blob', 
                });
                const mimeType = response.headers['content-type'];
                const url = URL.createObjectURL(response.data);

                console.log('Audio URL:', url);
                console.log('MIME Type:', mimeType);

                const sound = new Howl({
                    src: [url],
                    format: ['mp3', 'wav', 'ogg'], 
                    onload: () => {
                        console.log('Audio loaded successfully');
                        setAudioSrc(sound);
                        setDuration(sound.duration());
                    },
                    onloaderror: (id, error) => {
                        console.error('Error loading audio:', error);
                    },
                    onplay: () => {
                        setIsPlaying(true);
                        requestAnimationFrame(updateSeekbar);
                    },
                    onpause: () => {
                        setIsPlaying(false);
                    },
                    onstop: () => {
                        setIsPlaying(false);
                    },
                    onend: () => {
                        setIsPlaying(false);
                        setCurrentTime(duration); 
                        updateSeekbarBackground(duration, duration);
                    }
                });
                
            } catch (error) {
                console.error('Error fetching or converting audio:', error);
            }
        };

        fetchAndConvertAudio();
    }, [src]);

    const togglePlay = () => {
        if (audioSrc) {
            if (audioSrc.playing()) {
                audioSrc.pause();
            } else {
                audioSrc.play();
            }
        }
    };

    const updateSeekbar = () => {
        if (audioSrc && audioSrc.playing()) {
            const current = audioSrc.seek();
            setCurrentTime(current);
            updateSeekbarBackground(current, duration);
            requestAnimationFrame(updateSeekbar);
        }
    };

    const handleSeek = (event) => {
        const seekTo = (event.target.value / 100) * duration;
        if (audioSrc && isFinite(seekTo)) {
            audioSrc.seek(seekTo);
            setCurrentTime(seekTo);
            updateSeekbarBackground(seekTo, duration);
        } else {
            console.error("Invalid seek value:", seekTo);
        }
    };

    const updateSeekbarBackground = (current, duration) => {
        if (duration > 0) {
            const progress = (current / duration) * 100;
            const seekbar = seekbarRef.current;
            if (seekbar) {
                seekbar.style.background = `linear-gradient(to right, #0378a6 ${progress}%, lightgray ${progress}%)`;
                seekbar.value = progress; // Update the seekbar thumb position
            }
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    useEffect(() => {
        updateSeekbarBackground(currentTime, duration);
    }, [currentTime, duration]);

    useEffect(() => {
        if (isPlaying) {
            const interval = setInterval(() => {
                if (audioSrc) {
                    const current = audioSrc.seek();
                    setCurrentTime(current);
                    updateSeekbarBackground(current, duration);
                }
            }, 1000); 

            return () => clearInterval(interval);
        }
    }, [isPlaying, audioSrc, duration]);

    return (
        <div className="custom-audio">
            <audio ref={audioRef} src={audioSrc} ></audio>
            <button className={`play-button ${isPlaying ? 'pause' : 'play'}`} onClick={togglePlay}>
                {isPlaying ? <i className="fas fa-pause"></i> : <i className="fas fa-play shift-right"></i>}
            </button>
            <div className="time-display">{formatTime(currentTime)}</div>
            <input
                type="range"
                className="seekbar"
                ref={seekbarRef}
                value={duration ? (currentTime / duration) * 100 : 0}
                max="100"
                onChange={handleSeek}
                onInput={handleSeek} 
                style={{
                    background: duration ? `linear-gradient(to right, #0378a6 ${(currentTime / duration) * 100}%, lightgray ${(currentTime / duration) * 100}%)` : ''
                }}
            />
        </div>
    );
};

export default CustomAudio;
