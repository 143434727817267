import React, { useState, useEffect, useRef } from 'react'
import Map from './Map';
import './style/tourDetails.css'
import StarRating from './StarRating';
import GuideInfo from './GuideInfo';
import Reviews from './Reviews';
import CustomAudio from './CustomAudio';
import apple from '../../assets/Home/apple.png'
import playstore from '../../assets/Home/playstore.png'
import { useParams } from 'react-router';

import '../Home/styles/tourist.css'
import '../Home/styles/mobile/tourist.css'

import {
  READ_TOUR_DATA,
  READ_TOUR_REVIEWS,
  READ_GUIDE_DATA,
} from '../../constants'

import logo from '../../assets/Home/insidartours_logo.png'
import leftChevron from '../../assets/icons/leftChevron.svg'

import axios from 'axios'

import { useLoaderDialog } from '../../contexts/LoaderDialogContext'

import { useNavigate } from 'react-router-dom';

const TourDetails = ({ onClose, toursRef }) => {
    const { tourId, tourGuideId } = useParams();

    const [latestTourData, setLatestTourData] = useState(null);
    const [reviews, setReviews] = useState(null);
    const [tourGuideInfo, setTourGuideInfo] = useState(null);
    
    const [isHidden, setIsHidden] = useState(false);

    const { showLoader, hideLoader } = useLoaderDialog()

    const navigate = useNavigate();

    useEffect(() => {
      hideLoader();
      const fetchData = async () => {
        const toursArray = [tourId]
        const tourRequests = toursArray.map((tour) => {
          return Promise.all([
            axios.get(READ_TOUR_DATA, {
              headers: { 'Content-Type': 'application/json' },
              params: { tourId: tourId },
            }),
            axios.get(READ_TOUR_REVIEWS, {
              headers: { 'Content-Type': 'application/json' },
              params: { tourId: tourId },
            }),
            axios.get(READ_GUIDE_DATA, {
              headers: { 'Content-Type': 'application/json' },
              params: { uid: tourGuideId },
            }),
          ]);
        });
  
        const tourResponses = await Promise.all(tourRequests);
  
        const newLatestData = {};
  
        tourResponses.forEach(([tourRes, reviewsRes, guideRes], index) => {
          newLatestData[tourId] = {};
  
          if (tourRes.status === 200) {
            const tour = tourRes.data.data;
            newLatestData[tourId].details = tour.details;
          }
  
          if (reviewsRes.status === 200) {
            const reviewsData = reviewsRes.data.data.reviews;
            const totalRating = reviewsData.reduce((sum, review) => sum + parseFloat(review.rating), 0);
            const avgRating = totalRating / reviewsData.length;
  
            newLatestData[tourId].reviews = {
              averageRating: avgRating.toFixed(1),
              reviewCount: reviewsData.length,
              reviews: reviewsData,
            };
          }
  
          if (guideRes.status === 200) {
            const tourGuide = guideRes.data.data;
            newLatestData[tourId].tourGuide = tourGuide;
          }
        });
  
        setLatestTourData(newLatestData[tourId]?.details);
        setReviews(newLatestData[tourId]?.reviews);
        setTourGuideInfo(newLatestData[tourId]?.tourGuide);
        
      };
  
      fetchData();
    },[]);

    const handleOutsideClick = (event) => {
        if (event.target.className === 'modal') {
            onClose();
        }
    };

    
    const convertDistance = (meters) => {
      if(!meters){
          return null;
      }
      if(meters < 1000){
          return meters.toFixed(1) + " m"; 
      }
      return (meters / 1000).toFixed(2) + " km";  
    };

    const convertTime = (seconds) => {
      if(!seconds){
        return null;
      }
      const minutes = (seconds / 60).toFixed(2);  

      if (minutes < 60){
        return parseFloat(minutes).toFixed(0) + " min"
      } else {
        return (minutes / 60).toFixed(2) + " h";  
      }
    };

    const extractLanguage = (languageString) => {
      if(!languageString){
        return null;
      }

      const match = languageString.match(/^([^\[]+)/);
      const language = match ? match[1].trim() : languageString;
      return language.charAt(0).toUpperCase() + language.slice(1).toLowerCase();
    };

    const navigateToHome = () => {
      showLoader();
      setIsHidden(true);
      navigate('/', { state: { scrollToTours: true } });
    }

    return (
            <div className="details-content">
                {/*<div className="closeSuperlative" onClick={onClose}>&times;</div>*/}
                <div className='header'>
                  <div className='back-to-home' onClick={navigateToHome}> 
                    <img src={leftChevron} alt="left-chevron" />
                    <p> Go to Home </p>
                  </div>
                  <div className="logosTD">
                    <img src={logo} alt="logo" onClick={navigateToHome}/>
                  </div>
                </div>
                {latestTourData === null && <div className='center'><div className='spinner'></div></div>}
                {latestTourData && <div className="modal-content-divider">
                    <div className="map-container-wrapper">
                      <Map stops={Object.values(latestTourData?.stops)} />
                    </div>
                    <div className='details-container'>
                        <h1 className='tour-name'>
                          {latestTourData?.name}
                          {/*<div className="close" onClick={onClose}>&times;</div>*/}
                        </h1>
                        <div className='tour-subtext'> 
                            <div className='tour-sub-subtext'>
                              <p> {latestTourData?.tourCity} , {latestTourData?.tourCountry} </p>
                              {reviews?.averageRating && (
                                  <div className='tour-rating'>
                                      <StarRating rating={reviews?.averageRating} />
                                      <p className='average-rating'>{reviews?.averageRating}</p>
                                      {reviews?.reviewCount && <p>({reviews?.reviewCount} ratings)</p>}
                                  </div>
                              )}
                            </div>
                            <p className='tour-price'>{latestTourData?.tourPrice} {latestTourData?.tourCurrency}</p>
                        </div>
                        <div className='scrollable-section'>
                            <h3 className='description-heading'> Tour Description</h3>
                            {latestTourData?.centralAudio && (
                                <div className='audio-area'>
                                  <CustomAudio src={latestTourData?.centralAudio.url} isHidden={isHidden} />
                                </div>
                            )}
                            <br/>
                            <p>{latestTourData?.tourismDescription}</p>
                            <div className='details-grid'>
                              <p><strong>Distance:</strong> {convertDistance(latestTourData?.tourDistance)}</p>
                              <p><strong>Duration:</strong> {convertTime(latestTourData?.tourDuration)}</p>
                              <p><strong>Specialty:</strong> {latestTourData?.tourSpecialty} </p>
                              <p><strong>Language:</strong> {extractLanguage(latestTourData?.tourLanguage)} </p>
                              <p><strong>Type:</strong> {latestTourData?.tourType} </p>
                              <p><strong>Mode:</strong> {latestTourData?.tourMode} </p>
                            </div>
                            <br/>
                            <br/>

                            <h3>Your Guide</h3>
                            {tourGuideInfo ? <GuideInfo guide={tourGuideInfo} /> : <p>Loading guide information...</p>}
                            <br/>
                            <br/>

                            <h3>Reviews</h3>
                            {reviews ? <Reviews reviewsData={reviews} /> : <p>Loading reviews...</p>}
                            <br/>
                            <div className='buttons'>
                              <div className='AdvertBtn'>
                                <button >
                                  <div>
                                    <img src={apple} alt="appleLogo" />
                                  </div>
                                  <div>
                                    {' '}
                                    <div> DOWNLOAD ON THE</div>
                                    <div className="bold">
                                      {' '}
                                      <span> APP STORE </span>
                                    </div>
                                  </div>
                                </button>
                              </div>

                              <div className='AdvertBtn'>
                                <button>
                                  <div>
                                    <img src={playstore} alt="playstore" />
                                  </div>
                                  <div className="playbtn">
                                    <div>GET IT ON</div>
                                    <div className="bold">
                                      <span>GOOGLE PLAY</span>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </div>
                            <div className='buttonsDos'>
                              <div className='AdvertBtnTD'>
                                <button >
                                  <div>
                                    <img src={apple} alt="appleLogo" />
                                  </div>
                                  <div>
                                    {' '}
                                    <div> DOWNLOAD ON THE</div>
                                    <div className="bold">
                                      {' '}
                                      <span> APP STORE </span>
                                    </div>
                                  </div>
                                </button>
                              </div>

                              <div className='AdvertBtnTD'>
                                <button>
                                  <div>
                                    <img src={playstore} alt="playstore" />
                                  </div>
                                  <div className="playbtn">
                                    <div>GET IT ON</div>
                                    <div className="bold">
                                      <span>GOOGLE PLAY</span>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>);
}

export default TourDetails;