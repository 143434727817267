import React from 'react'

import './style/loaderDialog.css'

const LoaderDialog = () => {
  return (
    <div className="loader">
      <div className="spinner"></div>
    </div>
  )
}

export default LoaderDialog
