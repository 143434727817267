import React, { useState } from 'react'
import { useLoaderDialog } from '../../contexts/LoaderDialogContext'

import { useLocation, useNavigate } from 'react-router-dom'

import logo from '../../assets/Home/Logo.png'
import Textlogo from '../../assets/Home/TextLogo.png'
import MenuIcon from '../../assets/imgs/menu.png'
import LanguageIcon from '../../assets/icons/language.svg'
import CloseIcon from '../../assets/Home/close.png'

import './styles/nav.css'
import './styles/mobile/nav.css'

function NavHome({
  isSolid,
  language,
  sectionRefs,
  languagesList,
  setLanguage,
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const { showLoader } = useLoaderDialog()
  const [dropdown, setDropdown] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  let langDataset = {
    fr: 'French',
    ar: 'Arabic',
    default: 'English',
    ur: 'Urdu',
  }

  return (
    <div id="NavHome">
      <div className="logos">
        <img src={logo} alt="logo" />
      </div>
      <div className="homeLinks">
        <div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              sectionRefs[0].current.scrollIntoView()
            }}
          >
            HOME
          </a>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              sectionRefs[1].current.scrollIntoView()
            }}
          >
            ABOUT US
          </a>
          {location.pathname.includes('guide') 
            ? null
            : <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                sectionRefs[2].current.scrollIntoView()
              }}
            >
              TOURS
            </a>
          }
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              sectionRefs[3].current.scrollIntoView()
            }}
          >
            CONTACT US
          </a>
        </div>
      </div>
      <div className="Navhome-buttons">
        <button className="dropdown" onClick={() => setDropdown(!dropdown)}>
          <img src={LanguageIcon} alt="dropdown" width="40px" />
          <span className="langName">{langDataset[language]?.toUpperCase()}</span>
        </button>
        <div
          className={location.pathname.includes('guide') ? "dropdown-content-2" : "dropdown-content"}
          style={{ display: dropdown ? '' : 'none' }}
        >
          {languagesList.map((i) => (
            <li
              className={localStorage.getItem('lang') == i ? 'activeLang' : ''}
              onClick={() => {
                localStorage.setItem('lang', i)
                setLanguage(i)
                setDropdown(false)
              }}
            >
              {langDataset[i]?.toUpperCase()}
            </li>
          ))}
        </div>
        {location.pathname.includes('guide') &&
          <div className="signUpButton">
            <button
              onClick={() =>
                window
                  .open('https://builder.insidartours.com/', '_blank')
                  .focus()
              }
            >
              GUIDE SIGN UP / LOGIN
            </button>
          </div>
        }
        <button
          className="nav_switch_btn"
          onClick={() => {
            showLoader()
            navigate(location.pathname.includes('guide') ? '/' : '/guide')
          }}
        >
          SWITCH TO {location.pathname.includes('guide') ? 'TOURIST' : 'GUIDE'}
        </button>
        <button className="menu_btn">
          <img src={MenuIcon} alt="menu" onClick={() => setIsMenuOpen(true)} />
        </button>
      </div>

      {isMenuOpen ? (
        <div className="mobile_menu">
          <div className="close_menu">
            <img
              src={CloseIcon}
              alt="close"
              onClick={() => setIsMenuOpen(false)}
            />
          </div>
          <div className="menu_links">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                sectionRefs[0].current.scrollIntoView()
                setIsMenuOpen(false)
              }}
            >
              HOME
            </a>
            {location.pathname.includes('guide') 
              ? null
              : <a
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  sectionRefs[5].current.scrollIntoView()
                  setIsMenuOpen(false)
                }}
              >
                TOURS
              </a>
            }
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                sectionRefs[4].current.scrollIntoView({ behavior: 'smooth' })
                setIsMenuOpen(false)
              }}
            >
              ABOUT US
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                sectionRefs[3].current.scrollIntoView()
                setIsMenuOpen(false)
              }}
            >
              CONTACT US
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default NavHome
