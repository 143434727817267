import React, { useEffect, useState } from 'react';
import './style/reviews.css';
import StarRating from './StarRating';

import { useProfileViewer } from '../../contexts/ProfileViewerContext';

import anonymous from '../../assets/Home/profile.png'
import { READ_USER_DATA } from '../../constants';

import axios from 'axios'

const Reviews = ({ reviewsData }) => {
    const reviews = reviewsData.reviews.slice(0,5) ?? []

    const [reviewerProfiles, setReviewerProfiles] = useState(null);

    const { idToken } = useProfileViewer();

    useEffect(() => {
        const fetchReviewerProfiles = async () => {
            const profiles = await Promise.all(reviews.map(async (review) => {
                try {
                    const response = await axios
                        .get(READ_USER_DATA, {
                            headers: {
                                Authorization: `Bearer Token ${idToken}`,
                            },
                            params: {
                                uid: review.reviewerId,
                                userType: 'tourist',
                            },
                        })

                    const data = response.data.data;
                    return {
                        reviewerId: review.reviewerId,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        profilePictureURL: data.profilePictureURL,
                    };
                } catch (error) {
                    console.error(`Failed to fetch profile for reviewerId ${review.reviewerId}:`, error);
                    return null;
                }
            }));
            
            const profilesMap = profiles
                .filter(profile => profile !== null)
                .reduce((map, profile) => {
                    const firstName = profile.firstName || '';
                    const lastName = profile.lastName || '';
                    const name = (firstName || lastName) ? `${firstName} ${lastName}`.trim() : 'Anonymous';

                    map[profile.reviewerId] = {
                        name: name,
                        profilePictureURL: profile.profilePictureURL,
                    };
                    return map;
                }, {});

            setReviewerProfiles(profilesMap);
        };

        if (idToken) {
            fetchReviewerProfiles();
        }
    }, [idToken, reviews]);

    if (!reviewsData) return null;

    return (
        <div className="reviews">
            {reviews.length > 0 ? (
                <div>
                    {reviews.map((review) => (
                        <div key={review.reviewId} className="review">
                            <div className='review-core'>
                                {reviewerProfiles && <img className="reviewer-profile-picture" src={reviewerProfiles ? reviewerProfiles[review.reviewerId]["profilePictureURL"] ? reviewerProfiles[review.reviewerId]["profilePictureURL"] : anonymous : anonymous} />}
                                <div className='review-sub-core'>
                                    <p className='reviewer-name'> {reviewerProfiles ? reviewerProfiles[review.reviewerId]["name"] : "..."} </p>
                                    <p className="review-text">{review.review || "No review text provided."}</p>
                                </div>
                            </div>
                            <StarRating rating={parseFloat(review.rating)} />
                        </div>
                    ))}
                    {reviewsData?.reviews?.length > 5 && <p className='sub-text'> view all reviews on the app! </p>}
                </div>
            ) : (
                <p className='reviews-center'>No reviews available.</p>
            )}
        </div>
    );
};

export default Reviews;
