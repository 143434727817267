import InsidLogo from '../../assets/Home/insidartours_footer_logo.png'
import './styles/footer.css'
import fb from '../../assets/Home/f.png'
import Insta from '../../assets/Home/insta.png'
import LinkedIn from '../../assets/Home/in.png'
import twitter from '../../assets/Home/twitter.png'

function Footer({
  sectionRefs,
}) {
  return (
    <div id="footer">
      <div>
        <img src={InsidLogo} alt="Logo" />
      </div>
      <div>
        <div>
          <label>About InsidARTours</label>
        </div>
        <div className="aboutInsid">
          <p>
            <a 
              href="#"
              onClick={(e) => {
                e.preventDefault()
                sectionRefs[0].current.scrollIntoView()
              }}>
              About Us
            </a>
          </p>
          {sectionRefs.length > 1 && <p>
            <a 
              href="#"
              onClick={(e) => {
                e.preventDefault()
                sectionRefs[1].current.scrollIntoView()
              }}>
              Explore Tours
            </a>
          </p>}
        </div>
      </div>

      <div className="support">
        <div>
          <label>Support</label>
          <p>support@insidartours.com</p>
        </div>
        <div>
          <label>Inquiries</label>
          <p>info@insidartours.com</p>
        </div>
        {/*<div>
          <label>Sitemap</label>
        </div>*/}
      </div>

      <div className="social">
        <div>
          <label>Stay in Touch</label>
        </div>
        <div className="socialbtns">
          <button>
            <img src={fb} alt="fbLogo" />
          </button>
          <button>
            <img src={LinkedIn} alt="linkdedIn" />
          </button>
          <button>
            <img src={Insta} alt="insta" />
          </button>
          <button>
            <img src={twitter} alt="twitter" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Footer
