import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLoaderDialog } from '../../contexts/LoaderDialogContext'
import axios from 'axios'

import NavHome from './HomeNav'
import Feature from './Feature'
import Footer from '../Footer/Footer'
import TourCard from '../TourCards/TourCards'
import GuidePage from '../GuidePage/GuidePage'

import {
  FIREBASE_API_KEY,
  READ_AVAILABLE_LANGUAGE,
  READ_LANGUAGE,
  READ_WEBSITE_DATA,
} from '../../constants'

import overlay from '../../assets/Home/overlayimg.png'
import phone from '../../assets/Home/phone.png'
import apple from '../../assets/Home/apple.png'
import playstore from '../../assets/Home/playstore.png'
import cImg from '../../assets/Home/cImg.png'
import arrow from '../../assets/Home/arrow.png'
import phone2 from '../../assets/Home/phone2.png'
import AboutUsTopLeft from '../../assets/Home/about_us_top_left.png'
import AboutUsTopRight from '../../assets/Home/about_us_top_right.png'
import AboutUsBottomLeft from '../../assets/Home/about_us_bottom_left.png'
import AboutUsBottomRight from '../../assets/Home/about_us_bottom_right.png'
import ExpImg01 from '../../assets/Home/exp_01.png'
import ExpImg02 from '../../assets/Home/exp_guide_02.png'
import ExpImg03 from '../../assets/Home/exp_guide_03.png'
import ExpImg04 from '../../assets/Home/exp_04.png'
import BecomeAGuideImg from '../../assets/Home/become_a_guide.png'

import './styles/guide.css'
import './styles/mobile/guide.css'

function HomeTourGuide() {
  const location = useLocation()
  const navigate = useNavigate()
  const { isLoading, showLoader, hideLoader } = useLoaderDialog()

  const [isNavbarSolid, setNavbarSolid] = useState(false)
  const [websiteContent, setWebsiteContent] = useState({})

  // useEffect(() => {
  //     window.addEventListener("scroll", (e) => {
  //     setNavbarSolid(window.scrollY > 0);
  //     });
  // }, []);

  const homeRef = useRef(null)
  const aboutUsRef = useRef(null)
  const toursRef = useRef(null)
  const contactUsRef = useRef(null)
  const [language, setLanguage] = useState()
  const [availableLanguages, setAvailableLanguages] = useState([])

  useEffect(() => {
    let currentlanguage = localStorage.getItem('lang')
    if (currentlanguage != undefined) {
      setLanguage(currentlanguage)
    }
  }, [])

  useEffect(() => {
    //Api for getting available languages
    axios
      .get(READ_AVAILABLE_LANGUAGE, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          userType: 'guide',
        },
      })
      .then((response) => {
        if (response.status == 200) {
          let respD = response.data.data.home.guide
          setAvailableLanguages([...respD, 'default'])
        }
      })

    axios
      .get(READ_WEBSITE_DATA, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          userType: 'guide',
          project: 'home',
          filtered: 'false',
          code: language,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          let updatedWebsiteContent = res.data.data

          setWebsiteContent(updatedWebsiteContent)

          hideLoader()
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }, [language])

  const getIntro = (text) => {
    if(text == "START, GROW, AND SCALE YOUR LOCAL TOUR BUSINESS"){
      /*return <> <span className="blue_simple"> START, </span> <span className="blue_simple"> GROW, </span> AND <span className="blue_simple"> SCALE </span> YOUR LOCAL TOUR BUSINESS </>*/
      return <> START, GROW, SCALE YOUR LOCAL TOUR BUSINESS </>
    }else{
      return text
    }
  } 

  const getFeaturesHeading = (text) => {
    if(text == "KEY FEATURES AND BENEFITS OF INSIDARTOURS"){
      return <> <span className="normal_text"> KEY </span> <span className="blue_text"> FEATURES AND BENEFITS </span> <span className="normal_text"> OF INSIDARTOURS </span> </>
    }else{
      return text
    }
  }

  if (isLoading) {
    return <></>
  }

  return (
    <div id="HomePage" className="homepage_tour_guide">
      <div
        className="hero_section"
        style={{
          backgroundImage: `url(${websiteContent['pictures']['hero_bg']['pictureURL']})`,
        }}
      >
        <NavHome
          isSolid={isNavbarSolid}
          language={language}
          setLanguage={setLanguage}
          languagesList={availableLanguages}
          sectionRefs={[homeRef, aboutUsRef, toursRef, contactUsRef, aboutUsRef]}
        />
        <div ref={homeRef} id="HomeIntro">
          <div className="guide_nav_buttons navhome_buttons_for_mobile">
            <div className="downloadbtn">
              <button
                onClick={() =>
                  window
                    .open('https://builder.insidartours.com/', '_blank')
                    .focus()
                }
              >
                GUIDE SIGN UP / LOGIN
              </button>
            </div>
            <button
              className="nav_switch_btn navhome_buttons_for_mobile dark_background"
              onClick={() => {
                showLoader()
                navigate(location.pathname.includes('guide') ? '/' : '/guide')
              }}
            >
              SWITCH TO TOURIST
            </button>
          </div>

          <div className="Intro">
            <h1>{getIntro(websiteContent['text']['hero_title']['text'])}</h1>
          </div>

          <div className="desc">
            <p>{websiteContent['text']['hero_para']['text']}</p>
          </div>

          <div 
            className="downloadbtn"
            onClick={() =>
              window
                .open('https://builder.insidartours.com/', '_blank')
                .focus()
            }>
            <button>GET STARTED</button>
          </div>
        </div>
      </div>

      <div>
        <GuidePage content={websiteContent} />{' '}
      </div>

      <div ref={aboutUsRef} className="GuidanceP">
        <div className="guidance about_us_grid_row">
          <div
            className="left-guide"
            style={{
              background:
                "linear-gradient(180deg, #001f2b 0%, rgba(0, 31, 43, 0) 100%), url('" +
                websiteContent['pictures']['card_1_img']['pictureURL'] +
                "') no-repeat center",
              backgroundSize: 'cover',
            }}
          >
            <div>
              <h2 className='orangeText'>{websiteContent['text']['card_1_title']['text']}</h2>
            </div>
            <div>
              <span>{websiteContent['text']['card_1_para']['text']}</span>
            </div>
          </div>
          <div
            className="right-guide"
            style={{
              background: `linear-gradient(180deg, rgba(0, 31, 43, 0) 0%, #001f2b 100%), url('${websiteContent['pictures']['card_2_img']['pictureURL']}') no-repeat center`,
              backgroundSize: 'cover',
            }}
          >
            <div>
              <h2 className='orangeText'>{websiteContent['text']['card_2_title']['text']}</h2>
            </div>
            <div>
              <span>{websiteContent['text']['card_2_para']['text']}</span>
            </div>
          </div>
        </div>
        <div className="guidance about_us_grid_row">
          <div
            className="left-guide"
            style={{
              background:
                "linear-gradient(180deg, #001f2b 0%, rgba(0, 31, 43, 0) 100%), url('" +
                websiteContent['pictures']['card_3_img']['pictureURL'] +
                "') no-repeat center",
              backgroundSize: 'cover',
            }}
          >
            <div>
              <h2 className='orangeText'>{websiteContent['text']['card_3_title']['text']}</h2>
            </div>
            <div>
              <span>{websiteContent['text']['card_3_para']['text']}</span>
            </div>
          </div>
          <div
            className="right-guide"
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 31, 43, 0) 0%, #001f2b 100%), url('" +
                websiteContent['pictures']['card_4_img']['pictureURL'] +
                "') no-repeat center",
              backgroundSize: 'cover',
            }}
          >
            <div>
              <h2 className='orangeText'>{websiteContent['text']['card_4_title']['text']}</h2>
            </div>
            <div>
              <span>{websiteContent['text']['card_4_para']['text']}</span>
            </div>
          </div>
        </div>
      </div>

      

      <div className="pullUp">
        <h1 className='ProcessH blackText'>START SELLING IN MINUTES </h1>
        <div ref={toursRef} className="ProcessPage">
          <div className="innerBorderTG"></div>

          <div className="Process">
            {[1, 2, 3, 4, 5].map((index) => (
              <React.Fragment key={`flow_${index}`}>
                <div className="ProcessCard">
                  <div>
                    <img
                      src={
                        websiteContent['pictures'][`flow_${index}_img`][
                          'pictureURL'
                        ]
                      }
                      alt="Img"
                    />
                  </div>
                  <div className="PCardH">
                    <span>
                      {websiteContent['text'][`flow_${index}_title`]['text']}
                    </span>
                  </div>
                  <div className="PCardP">
                    <span>
                      {websiteContent['text'][`flow_${index}_para`]['text']}
                    </span>
                  </div>
                </div>

                {index < 5 ? (
                  <div className="arrow">
                    <img src={arrow} alt="arrow" />
                  </div>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="create-tour-btn" style={{ marginTop: '10vh' }}>
          <a 
            onClick={() =>
              window
                .open('https://builder.insidartours.com/', '_blank')
                .focus()
            }>
            <button>CREATE YOUR FIRST TOUR</button>
          </a>
        </div>
      </div>

      {/*TODO: shift these texts to Admin Dashboard*/}
      <div>
          <div className="AdvertTG">
            <div className="AdvertInfo">
              <div className="AdvertTGH">
                <img src="/images/blueQuotes.svg" />
                <h2>
                  {"EASILY CREATE AND CUSTOMIZE ENGAGING TOURS WITH TOURBUILDER"}
                  <br />
                </h2>
              </div>
              <div className="AdvertTGP">
                {"Creating a tour is as easy as posting on Instagram! InsidARtours TourBuilder is user-friendly and intuitive."}
                <ul class="blueBullets">
                  <li>Plan and map out your tour, upload audio, pin interesting places on the map, and add interactive features with ease on InsidARtours platform.</li>
                  <li>Add vintage or famous photos for different stops</li>
                  <li>Upload music and audio guides</li>
                </ul>
                <a 
                  onClick={() =>
                    window
                      .open('https://builder.insidartours.com/', '_blank')
                      .focus()
                  }>
                  Try TourBuilder now →
                </a>
              </div>
              <div className="AdvertBtn">
                <div>
                  <button>
                    <div>
                      <img src={apple} alt="appleLogo" />
                    </div>
                    <div>
                      {' '}
                      <div> DOWNLOAD ON THE</div>
                      <div className="bold">
                        {' '}
                        <span> APP STORE </span>
                      </div>
                    </div>
                  </button>
                </div>

                <div>
                  <button>
                    <div>
                      <img src={playstore} alt="playstore" />
                    </div>
                    <div className="playbtn">
                      <div>GET IT ON</div>
                      <div className="bold">
                        <span>GOOGLE PLAY</span>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>

            <div className="AdvertImg">
              <img
                src="/images/mobileApp.png"
                alt="phoneImg"
              />
            </div>

            <div className="AdvertBtn advert_btn_for_mobile">
              <div>
                <button>
                  <div>
                    <img src={apple} alt="appleLogo" />
                  </div>
                  <div>
                    {' '}
                    <div> DOWNLOAD ON THE</div>
                    <div className="bold">
                      {' '}
                      <span> APP STORE </span>
                    </div>
                  </div>
                </button>
              </div>

              <div>
                <button>
                  <div>
                    <img src={playstore} alt="playstore" />
                  </div>
                  <div className="playbtn">
                    <div>GET IT ON</div>
                    <div className="bold">
                      <span>GOOGLE PLAY</span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

      <div className="FeaturesP">
        <Feature
          heading={getFeaturesHeading(websiteContent['text']['features_title']['text'])}
          features={websiteContent['features']}
          isGuide={true}
        />
      </div>


      {/*TODO: shift these texts to Admin Dashboard*/}
      <div>
        <div className="AdvertTG2">
          <div className="AdvertInfo">
            <div className="AdvertTGH">
              <img src="/images/blueQuotes.svg" />
              <h2>
                BECOME A GUIDE
                <br />
              </h2>
            </div>
            <div className="AdvertTGP2">
              {"Earn money without meeting a single tourist. Share your own unique experiences through digital tours and keep your day job. InsidARtours is the perfect way to earn extra money - whether you're a tour guide, blogger, local expert, student or stay-at-home mom."}
              <a 
                onClick={() =>
                  window
                    .open('https://builder.insidartours.com/', '_blank')
                    .focus()
                }>
                Try TourBuilder now →
              </a>
            </div>
          </div>
            

          <div className="AdvertTGImg2">
            <img
              className='roundedCorners'
              src="/images/guide.png"
              alt="guide"
            />
          </div>
        </div>
      </div>

      <div className="FeaturesP pullUpMobile">
        <Feature
          heading={"PROFESSIONAL TOUR GUIDES:"}
          features={websiteContent['guideBenefits']}
          isGuide={true}
        />
      </div>

      <div className="create-tour-btn" style={{ marginBottom: '8vh' }}>
        <a 
          onClick={() =>
            window
              .open('https://builder.insidartours.com/', '_blank')
              .focus()
          }>
          <button>TRY TOURBUILDER</button>
        </a>
      </div>

      <div ref={contactUsRef} className="Footer">
        <Footer sectionRefs={[aboutUsRef]}/>
      </div>
    </div>
  )
}

export default HomeTourGuide
